import { Observable } from 'rxjs';

import { SuccessVoid } from '@infra/success/success';
import { InscricaoEntity } from '@domain/entities/inscricao/inscricao.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorParam } from '@shared/utils/paginator';

export abstract class InscricaoRemoteDataSource {
    abstract getAllInscricaoByTurmaPaginated(idPublic: string, params: PaginatorParam): Observable<PaginatorEntity<InscricaoEntity[]>>;
    abstract getAllMyInscricaoPaginated(params: PaginatorParam): Observable<PaginatorEntity<InscricaoEntity[]>>;
    abstract getMyInscricaoByIdPublic(idPublic: string): Observable<InscricaoEntity>;
    abstract getMyInscricaoWithTermoByIdPublic(idPublic: string): Observable<InscricaoEntity>;
    abstract updateTermoMyInscricaoByIdPublic(idPublic: string): Observable<SuccessVoid>;
    abstract deleteInscricaoByIdPublic(idPublic: string, minhaInscricao?: boolean): Observable<SuccessVoid>;
    abstract redefinirInscricaoByIdPublic(idPublic: string): Observable<SuccessVoid>;
    abstract notificacaoInscricaoByIdPublic(idPublic: string): Observable<SuccessVoid>;
    abstract deferimentoInscricaoByIdPublic(idPublic: string, status: boolean): Observable<SuccessVoid>;

    abstract getDocumentoVinculoInscricaoByIdPublic(idPublic: string): Observable<Blob>;
}