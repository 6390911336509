import { Observable } from 'rxjs';

import { CursoMoodleEntity } from '@domain/entities/curso-moodle/curso-moodle.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorParam } from '@shared/utils/paginator';
import { SuccessVoid } from '@infra/success/success';

export abstract class CursoMoodleRemoteDataSource {
    abstract getAllCursoMoodleStatusAtivo(): Observable<CursoMoodleEntity[]>;
    abstract syncCourseMoodle(): Observable<SuccessVoid>;
    abstract getAllCursoMoodlePaginated(params: PaginatorParam): Observable<PaginatorEntity<CursoMoodleEntity[]>>;
    abstract getCursoMoodleByIdPublic(idPublic: string): Observable<CursoMoodleEntity>;
}