import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthUserRemoteDataSource } from './auth-user-remote.datasource';
import { AuthEntity } from '@domain/entities/auth/auth.entity';
import { SuccessVoid } from '@infra/success/success';
import { AuthMapper } from '@data/models/auth/auth.mapper';
import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { SessionAuthed } from '@presentation/shared/utils/session-authed';

@Injectable()
export class AuthUserRemoteDataSourceImpl implements AuthUserRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: AuthMapper,
        private readonly _session: SessionAuthed,
    ) { }

    login(param: AuthEntity): Observable<SuccessVoid> {
        return this._http.post<SuccessVoid>(`${Endpoints.auth}/login`, this._mapper.mapFrom(param)).pipe(
            map(res => {

                if(res['token']) 
                    this._session.setKey(param.email, res['token']);

                if(res['user'])
                    this._session.setUserAuth(res['user']);

                return new SuccessVoid();
            })
        );
    }

    logout(): Observable<SuccessVoid> {
        return this._http.post<SuccessVoid>(`${Endpoints.auth}/logout`, {});
    }

    forgotPassword(param: AuthEntity): Observable<SuccessVoid> {
        return this._http.post<SuccessVoid>(`${Endpoints.auth}/forgot-password`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }

    resetPassword(param: AuthEntity): Observable<SuccessVoid> {
        return this._http.post<SuccessVoid>(`${Endpoints.auth}/reset-password`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }

    changePassword(param: AuthEntity): Observable<SuccessVoid> {
        return this._http.post<SuccessVoid>(`${Endpoints.auth}/change-password`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }
}