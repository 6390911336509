import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { SuccessVoid } from '@infra/success/success';
import { CategoriaRepository } from '@domain/repositories/categoria/categoria.repository';
import { CategoriaEntity } from '@domain/entities/categoria/categoria.entity';

@Injectable()
export class SaveCategoriaUseCase implements Usecase<CategoriaEntity, SuccessVoid> {

    constructor(
        private readonly _repository: CategoriaRepository
    ) { }

    execute(param: CategoriaEntity): Observable<SuccessVoid | Failure> {
        return this._repository.saveCategoria(param).pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<SuccessVoid>) => res.right)
        );
    }
}