import { Observable } from 'rxjs';

import { UserEntity } from '@domain/entities/user/user.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { SuccessVoid } from '@infra/success/success';
import { PaginatorParam } from '@shared/utils/paginator';

export abstract class UserRemoteDataSource {
    abstract getUserAuth(): Observable<UserEntity>;
    abstract saveUserExterno(param: UserEntity): Observable<SuccessVoid>;
    abstract resetPassword(param: any): Observable<SuccessVoid>;
    abstract getAllUserStatusAtivo(): Observable<UserEntity[]>;
    abstract getAllUserPaginated(params: PaginatorParam): Observable<PaginatorEntity<UserEntity[]>>;
    abstract getUserByIdPublic(idPublic: string): Observable<UserEntity>;
    abstract updateStatusUserByIdPublic(idPublic: string, status: boolean): Observable<SuccessVoid>;
    abstract updateUserByIdPublic(param: UserEntity, idPublic: string): Observable<SuccessVoid>;
    abstract saveUser(param: UserEntity): Observable<SuccessVoid>;

    abstract updateUserInternoByIdPublic(param: UserEntity, idPublic: string): Observable<SuccessVoid>;
}