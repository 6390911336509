import { Injectable } from '@angular/core';

import { MunicipioModel } from './municipio.model';
import { EstadoMapper } from '../estado/estado.mapper';
import { Mapper } from "@data/contracts/mapper.contract";
import { MunicipioEntity } from '@domain/entities/municipio/municipio.entity';

@Injectable()
export class MunicipioMapper implements Mapper<MunicipioModel, MunicipioEntity> {
    
    constructor(
        private readonly _mapper: EstadoMapper,
    ) { }

    mapFrom(param: MunicipioEntity): MunicipioModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            estado: this._mapper.mapFrom(param?.estado),
            nome: param?.nome,
        };
    }

    mapTo(param: MunicipioModel): MunicipioEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            estado: this._mapper.mapTo(param?.estado),
            nome: param?.nome,
        };
    }
}