import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PerfilRemoteDataSource } from './perfil-remote.datasource';
import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { PerfilMapper } from '@data/models/perfil/perfil.mapper';
import { PerfilEntity } from '@domain/entities/perfil/perfil.entity';
import { PerfilModel } from '@data/models/perfil/perfil.model';

@Injectable()
export class PerfilRemoteDataSourceImpl implements PerfilRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: PerfilMapper,
    ) { }

    getAllPerfilStatusAtivo(): Observable<PerfilEntity[]> {
        return this._http.get<PerfilModel[]>(`${Endpoints.perfil}/ativo`).pipe(
            map(res => res['data']?.map(perfil => this._mapper.mapTo(perfil)))
        );
    }
}