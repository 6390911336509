import { Observable } from 'rxjs';
import { Either } from 'fp-ts/Either';

import { SuccessVoid } from '@infra/success/success';
import { Failure } from '@infra/contracts/failure.contract';
import { OrgaoEntity } from '@domain/entities/orgao/orgao.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorParam } from '@shared/utils/paginator';

export abstract class OrgaoRepository {
    abstract getAllOrgaoStatusAtivo(): Observable<Either<Failure, OrgaoEntity[]>>;
    abstract getAllOrgaoPaginated(params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<OrgaoEntity[]>>>;
    abstract getOrgaoByIdPublic(idPublic: string): Observable<Either<Failure, OrgaoEntity>>;
    abstract updateStatusOrgaoByIdPublic(idPublic: string, status: boolean): Observable<Either<Failure, SuccessVoid>>;
    abstract updateOrgaoByIdPublic(param: OrgaoEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>>;
    abstract saveOrgao(param: OrgaoEntity): Observable<Either<Failure, SuccessVoid>>;
}