
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OrgaoRemoteDataSource } from './orgao-remote.datasource';
import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { SuccessVoid } from '@infra/success/success';
import { OrgaoMapper } from '@data/models/orgao/orgao.mapper';
import { OrgaoEntity } from '@domain/entities/orgao/orgao.entity';
import { OrgaoModel } from '@data/models/orgao/orgao.model';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorMapper } from '@data/models/paginator/paginator.mapper';
import { PaginatorParam } from "@shared/utils/paginator";
import { HttpUtils } from '@shared/utils/http-utils';

@Injectable()
export class OrgaoRemoteDataSourceImpl implements OrgaoRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: OrgaoMapper,
        private readonly _mapperPaginator: PaginatorMapper<OrgaoEntity[]>,
    ) { }

    getAllOrgaoStatusAtivo(): Observable<OrgaoEntity[]> {
        return this._http.get<OrgaoModel[]>(`${Endpoints.orgao}/ativo`).pipe(
            map(res => res['data']?.map(orgao => this._mapper.mapTo(orgao)))
        );
    }
    
    /**
     * Busca todos os órgãos.
     * 
     * @param page number
     * @param size number
     * @param search string | null
     * @returns Observable<PaginatorEntity<OrgaoEntity[]>>
     */
    getAllOrgaoPaginated(params: PaginatorParam): Observable<PaginatorEntity<OrgaoEntity[]>> {
        return this._http.get<OrgaoModel[]>(`${Endpoints.orgao}`, { params: HttpUtils.buildParams(params.mapTo()) })
        .pipe(
            map((res) => this._mapperPaginator.mapPaging(
                res['data']?.map(orgao => this._mapper.mapTo(orgao)), 
                res['meta']
                )
            )
        );
    }

    getOrgaoByIdPublic(idPublic: string): Observable<OrgaoEntity> {
        return this._http.get<OrgaoEntity>(`${Endpoints.orgao}/${idPublic}`).pipe(
            map((res) => this._mapper.mapTo(res['data']))
        );
    }

    updateStatusOrgaoByIdPublic(idPublic: string, status: boolean): Observable<SuccessVoid> {
        return this._http.put<SuccessVoid>(`${Endpoints.orgao}/${idPublic}/status`, { status: status }).pipe(
            map(() => new SuccessVoid())
        );
    }

    updateOrgaoByIdPublic(param: OrgaoEntity, idPublic: string): Observable<SuccessVoid> {
        return this._http.put<SuccessVoid>(`${Endpoints.orgao}/${idPublic}`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }

    saveOrgao(param: OrgaoEntity): Observable<SuccessVoid> {
        return this._http.post<SuccessVoid>(`${Endpoints.orgao}`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }
}