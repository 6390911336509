import { Observable } from 'rxjs';

import { ProfessorEntity } from '@domain/entities/professor/professor.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { SuccessVoid } from '@infra/success/success';
import { PaginatorParam } from '@shared/utils/paginator';

export abstract class ProfessorRemoteDataSource {
    abstract getAllProfessorStatusAtivo(): Observable<ProfessorEntity[]>;
    abstract getMeuCredenciamentoProfessor(): Observable<ProfessorEntity>;
    abstract getAllProfessorPaginated(params: PaginatorParam): Observable<PaginatorEntity<ProfessorEntity[]>>;
    abstract getProfessorByIdPublic(idPublic: string): Observable<ProfessorEntity>;
    abstract updateStatusProfessorByIdPublic(idPublic: string, status: boolean): Observable<SuccessVoid>;
    abstract updateProfessorByIdPublic(param: ProfessorEntity, idPublic: string): Observable<SuccessVoid>;
    abstract updateProfessorCredenciamento(param: ProfessorEntity): Observable<SuccessVoid>;
    abstract saveProfessor(param: ProfessorEntity): Observable<SuccessVoid>;

    abstract deleteProfessorByIdPublic(idPublic: string): Observable<SuccessVoid>;
}