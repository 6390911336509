import { HttpParams } from '@angular/common/http';
import { Helps } from './helps';

export class HttpUtils {

    static buildParams(paramsObj: { [key: string]: any }): HttpParams {
        let params = new HttpParams();

        for (const key in paramsObj) {
            if (paramsObj.hasOwnProperty(key) && paramsObj[key] !== undefined) {
                params = params.set(key, paramsObj[key]);
            }
        }

        return params;
    }

    static createBlob(obj: any): Blob {
        return new Blob([JSON.stringify(obj)], {
            type: 'application/json',
        });
    }

    static jsonToFormData (json: Object, parentKey: string = ''): FormData {
        let objectJson = Helps.removerUndefinedAndEmptyObjects(json);

        return Object.entries(objectJson).reduce((formData: FormData, [key, value]) => {
            const appendToFormData = (data, parentKey = '') => {
                for (let innerKey in data) {
                    if (data.hasOwnProperty(innerKey)) {
                        const nestedKey = parentKey ? `${parentKey}[${innerKey}]` : innerKey;
                        const innerValue = data[innerKey] !== null ? data[innerKey] : '';

                        if(!(innerValue instanceof File) && (Array.isArray(innerValue) || (typeof innerValue === 'object' && innerValue !== null))) {
                            appendToFormData(innerValue, nestedKey);
                        } else formData.append(nestedKey, innerValue);
                    }
                }
            };

            appendToFormData({ [key]: value }, parentKey);

            return formData;
        }, new FormData());
    }
}