import { Observable } from 'rxjs';
import { Either } from 'fp-ts/Either';

import { SuccessVoid } from '@infra/success/success';
import { Failure } from '@infra/contracts/failure.contract';
import { CategoriaEntity } from '@domain/entities/categoria/categoria.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorParam } from '@shared/utils/paginator';

export abstract class CategoriaRepository {
    abstract getAllCategoriaStatusAtivo(): Observable<Either<Failure, CategoriaEntity[]>>;
    abstract getAllCategoriaPaginated(params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<CategoriaEntity[]>>>;
    abstract getCategoriaByIdPublic(idPublic: string): Observable<Either<Failure, CategoriaEntity>>;
    abstract updateStatusCategoriaByIdPublic(idPublic: string, status: boolean): Observable<Either<Failure, SuccessVoid>>;
    abstract updateCategoriaByIdPublic(param: CategoriaEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>>;
    abstract saveCategoria(param: CategoriaEntity): Observable<Either<Failure, SuccessVoid>>;
}