import { environment } from 'environments/environment';

export class Endpoints {
    static readonly urlAva = environment.ava;
    static readonly baseUrl = environment.api;
    
    static readonly auth = `${Endpoints.baseUrl}/auth`;
    static readonly perfil = `${Endpoints.baseUrl}/perfil`;
    static readonly user = `${Endpoints.baseUrl}/usuario`;
    static readonly auxiliar = `${Endpoints.baseUrl}/auxiliar`;
    static readonly orgao = `${Endpoints.baseUrl}/orgao`;
    static readonly categoria = `${Endpoints.baseUrl}/category`;
    static readonly documentacao = `${Endpoints.baseUrl}/documentacao`;
    static readonly curso = `${Endpoints.baseUrl}/curso`;
    static readonly cursoMoodle = `${Endpoints.baseUrl}/moddle`;
    static readonly turma = `${Endpoints.baseUrl}/turma`;
    static readonly inscricao = `${Endpoints.baseUrl}/inscricoes`;
    static readonly professor = `${Endpoints.baseUrl}/professor`;
    
    static readonly address = `${Endpoints.baseUrl}/address`;
}