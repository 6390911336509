import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuxiliarRemoteDataSource } from './auxiliar-remote.datasource';
import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { UserMapper } from '@data/models/user/user.mapper';
import { KeyAuxiliarEnum } from '@shared/enums/key-auxiliar.enum';
import { AuxiliarEntity } from '@domain/entities/auxiliar/auxiliar.entity';
import { AuxiliarModel } from '@data/models/auxiliar/auxiliar.model';
import { AuxiliarMapper } from '@data/models/auxiliar/auxiliar.mapper';

@Injectable()
export class AuxiliarRemoteDataSourceImpl implements AuxiliarRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: AuxiliarMapper
    ) { }

    getAllAuxiliarByChave(chave: KeyAuxiliarEnum): Observable<AuxiliarEntity[]> {
        return this._http.get<AuxiliarModel[]>(`${Endpoints.auxiliar}/${chave?.toLocaleLowerCase()}`).pipe(
            map(res => res['data']?.map(auxiliar => this._mapper.mapTo(auxiliar)))
        );
    }
}