import { Injectable } from '@angular/core';

import { FormacaoProfissionalModel } from './formacao-profissional.model';
import { AuxiliarMapper } from './../auxiliar/auxiliar.mapper';
import { Mapper } from "@data/contracts/mapper.contract";
import { FormacaoProfissionalEntity } from '@domain/entities/formacao-profissional/formacao-profissional.entity';

@Injectable()
export class FormacaoProfissionalMapper implements Mapper<FormacaoProfissionalModel, FormacaoProfissionalEntity> {

    constructor(
        private readonly _mapperAuxiliar: AuxiliarMapper,
    ) { }

    mapFrom(param: FormacaoProfissionalEntity): FormacaoProfissionalModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            formacao: this._mapperAuxiliar.mapFrom(param?.formacao),
            instituicao: param?.instituicao,
            curso: param?.curso,
            ano: param?.ano,
        };
    }

    mapTo(param: FormacaoProfissionalModel): FormacaoProfissionalEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            formacao: this._mapperAuxiliar.mapTo(param?.formacao),
            instituicao: param?.instituicao,
            curso: param?.curso,
            ano: param?.ano,
        };
    }
}