import { Observable } from 'rxjs';
import { Either } from 'fp-ts/Either';

import { Failure } from '@infra/contracts/failure.contract';
import { SuccessVoid } from '@infra/success/success';
import { InscricaoEntity } from '@domain/entities/inscricao/inscricao.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorParam } from '@shared/utils/paginator';

export abstract class InscricaoRepository {
    abstract getAllInscricaoByTurmaPaginated(idPublic: string, params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<InscricaoEntity[]>>>;
    abstract getAllMyInscricaoPaginated(params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<InscricaoEntity[]>>>;
    abstract getMyInscricaoByIdPublic(idPublic: string): Observable<Either<Failure, InscricaoEntity>>;
    abstract getMyInscricaoWithTermoByIdPublic(idPublic: string): Observable<Either<Failure, InscricaoEntity>>;
    abstract updateTermoMyInscricaoByIdPublic(idPublic: string): Observable<Either<Failure, SuccessVoid>>;
    abstract deleteInscricaoByIdPublic(idPublic: string, minhaInscricao?: boolean): Observable<Either<Failure, SuccessVoid>>;
    abstract redefinirInscricaoByIdPublic(idPublic: string): Observable<Either<Failure, SuccessVoid>>;
    abstract notificacaoInscricaoByIdPublic(idPublic: string): Observable<Either<Failure, SuccessVoid>>;
    abstract deferimentoInscricaoByIdPublic(idPublic: string, status: boolean): Observable<Either<Failure, SuccessVoid>>;

    abstract getDocumentoVinculoInscricaoByIdPublic(idPublic: string): Observable<Either<Failure, Blob>>;
}