import { UserMapper } from './../user/user.mapper';
import { Injectable } from '@angular/core';

import { ArquivoUsuarioModel } from './arquivo-usuario.model';
import { DocumentacaoMapper } from '../documentacao/documentacao.mapper';
import { Mapper } from "@data/contracts/mapper.contract";
import { ArquivoUsuarioEntity } from '@domain/entities/arquivo-usuario/arquivo-usuario.entity';

@Injectable()
export class ArquivoUsuarioMapper implements Mapper<ArquivoUsuarioModel, ArquivoUsuarioEntity> {

    constructor(
        private readonly _mapperUser: UserMapper,
        private readonly _mapperDocumentacao: DocumentacaoMapper,
    ) { }

    mapFrom(param: ArquivoUsuarioEntity): ArquivoUsuarioModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            user: this._mapperUser.mapFrom(param?.user),
            documentacao: this._mapperDocumentacao.mapFrom(param?.documentacao),
            caminho: param?.caminho,
            descricao_documento: param?.descricaoDocumento,
            file: param?.file,
        };
    }

    mapTo(param: ArquivoUsuarioModel): ArquivoUsuarioEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            user: this._mapperUser.mapTo(param?.user),
            documentacao: this._mapperDocumentacao.mapTo(param?.documentacao),
            caminho: param?.caminho,
            descricaoDocumento: param?.descricao_documento,

            file: param?.file,
        };
    }
}