import { Injectable } from '@angular/core';

import { AuxiliarModel } from './auxiliar.model';
import { Mapper } from "@data/contracts/mapper.contract";
import { AuxiliarEntity } from '@domain/entities/auxiliar/auxiliar.entity';

@Injectable()
export class AuxiliarMapper implements Mapper<AuxiliarModel, AuxiliarEntity> {
    mapFrom(param: AuxiliarEntity): AuxiliarModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            chave: param?.chave,
            valor: param?.valor,
            status: param?.status,
            descricao: param?.descricao,
        };
    }

    mapTo(param: AuxiliarModel): AuxiliarEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            chave: param?.chave,
            valor: param?.valor,
            status: param?.status,
            descricao: param?.descricao,
        };
    }
}