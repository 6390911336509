import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { OrgaoRemoteDataSource } from "@data/data-source/orgao/orgao-remote.datasource";
import { OrgaoRepository } from "@domain/repositories/orgao/orgao.repository";
import { OrgaoEntity } from "@domain/entities/orgao/orgao.entity";
import { PaginatorEntity } from "@domain/entities/paginator/paginator.entity";
import { Failure } from '@infra/contracts/failure.contract';
import { SuccessVoid } from "@infra/success/success";
import { PaginatorParam } from "@shared/utils/paginator";

@Injectable()
export class OrgaoRepositoryImpl implements OrgaoRepository {

    constructor(
        private readonly _remoteDataSource: OrgaoRemoteDataSource,
    ) { }

    getAllOrgaoPaginated(params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<OrgaoEntity[]>>> {
        return this._remoteDataSource.getAllOrgaoPaginated(params).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getAllOrgaoStatusAtivo(): Observable<Either<Failure, OrgaoEntity[]>> {
        return this._remoteDataSource.getAllOrgaoStatusAtivo().pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getOrgaoByIdPublic(idPublic: string): Observable<Either<Failure, OrgaoEntity>> {
        return this._remoteDataSource.getOrgaoByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    updateStatusOrgaoByIdPublic(idPublic: string, status: boolean): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateStatusOrgaoByIdPublic(idPublic, status).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    updateOrgaoByIdPublic(param: OrgaoEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateOrgaoByIdPublic(param, idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    saveOrgao(param: OrgaoEntity): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.saveOrgao(param).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }
}