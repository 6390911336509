import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { Failure } from '@infra/contracts/failure.contract';
import { CursoMoodleRemoteDataSource } from "@data/data-source/curso-moodle/curso-moodle-remote.datasource";
import { CursoMoodleRepository } from "@domain/repositories/curso-moodle/curso-moodle.repository";
import { CursoMoodleEntity } from "@domain/entities/curso-moodle/curso-moodle.entity";
import { PaginatorEntity } from "@domain/entities/paginator/paginator.entity";
import { PaginatorParam } from "@shared/utils/paginator";
import { SuccessVoid } from "@infra/success/success";

@Injectable()
export class CursoMoodleRepositoryImpl implements CursoMoodleRepository {

    constructor(
        private readonly _remoteDataSource: CursoMoodleRemoteDataSource,
    ) { }

    getAllCursoMoodlePaginated(params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<CursoMoodleEntity[]>>> {
        return this._remoteDataSource.getAllCursoMoodlePaginated(params).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getAllCursoMoodleStatusAtivo(): Observable<Either<Failure, CursoMoodleEntity[]>> {
        return this._remoteDataSource.getAllCursoMoodleStatusAtivo().pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getCursoMoodleByIdPublic(idPublic: string): Observable<Either<Failure, CursoMoodleEntity>> {
        return this._remoteDataSource.getCursoMoodleByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    syncCourseMoodle(): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.syncCourseMoodle().pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }
}