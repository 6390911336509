import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { EstadoMapper } from '@data/models/estado/estado.mapper';
import { EstadoRemoteDataSource } from './estado-remote.datasource';
import { EstadoEntity } from '@domain/entities/estado/estado.entity';
import { EstadoModel } from '@data/models/estado/estado.model';

@Injectable()
export class EstadoRemoteDataSourceImpl implements EstadoRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: EstadoMapper
    ) { }

    getAllEstados(): Observable<EstadoEntity[]> {
        return this._http.get<EstadoModel[]>(`${Endpoints.address}/estados`).pipe(
            map(res => res['data']?.map(estado => this._mapper.mapTo(estado)))
        );
    }
}