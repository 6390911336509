import { Observable } from 'rxjs';

import { CategoriaEntity } from '@domain/entities/categoria/categoria.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { SuccessVoid } from '@infra/success/success';
import { PaginatorParam } from '@shared/utils/paginator';

export abstract class CategoriaRemoteDataSource {
    abstract getAllCategoriaStatusAtivo(): Observable<CategoriaEntity[]>;
    abstract getAllCategoriaPaginated(params: PaginatorParam): Observable<PaginatorEntity<CategoriaEntity[]>>;
    abstract getCategoriaByIdPublic(idPublic: string): Observable<CategoriaEntity>;
    abstract updateStatusCategoriaByIdPublic(idPublic: string, status: boolean): Observable<SuccessVoid>;
    abstract updateCategoriaByIdPublic(param: CategoriaEntity, idPublic: string): Observable<SuccessVoid>;
    abstract saveCategoria(param: CategoriaEntity): Observable<SuccessVoid>;
}