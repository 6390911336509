import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserRemoteDataSource } from './user-remote.datasource';
import { UserMapper } from '@data/models/user/user.mapper';
import { UserEntity } from '@domain/entities/user/user.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { UserModel } from '@data/models/user/user.model';
import { PaginatorMapper } from '@data/models/paginator/paginator.mapper';
import { SuccessVoid } from '@infra/success/success';
import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { PaginatorParam } from '@shared/utils/paginator';
import { HttpUtils } from '@shared/utils/http-utils';

@Injectable()
export class UserRemoteDataSourceImpl implements UserRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: UserMapper,
        private readonly _mapperPaginator: PaginatorMapper<UserEntity[]>,
    ) { }

    getUserAuth(): Observable<UserEntity> {
        return this._http.get<UserModel>(`${Endpoints.user}/recupera-usuario`).pipe(
            map(res => this._mapper.mapTo(res['data']))
        );
    }
    
    resetPassword(param: any): Observable<SuccessVoid> {
        throw new Error('Method not implemented.');
    }
    
    saveUserExterno(param: UserEntity): Observable<SuccessVoid> {
        return this._http.post<SuccessVoid>(
            `${Endpoints.user}/criar-usuario-externo`, this._mapper.saveUserExterno(param)
        ).pipe(
            map(res => new SuccessVoid())
        );
    }

    getAllUserStatusAtivo(): Observable<UserEntity[]> {
        return this._http.get<UserModel[]>(`${Endpoints.user}/ativo`).pipe(
            map(res => res['data']?.map(user => this._mapper.mapTo(user)))
        );
    }
    
    /**
     * @param page number
     * @param size number
     * @param search string | null
     * @returns Observable<PaginatorEntity<UserEntity[]>>
     */
    getAllUserPaginated(params: PaginatorParam): Observable<PaginatorEntity<UserEntity[]>> {
        return this._http.get<UserModel[]>(`${Endpoints.user}`, { params: HttpUtils.buildParams(params.mapTo()) })
        .pipe(
            map((res) => this._mapperPaginator.mapPaging(
                res['data']?.map(user => this._mapper.mapTo(user)), 
                res['meta']
                )
            )
        );
    }

    getUserByIdPublic(idPublic: string): Observable<UserEntity> {
        return this._http.get<UserEntity>(`${Endpoints.user}/${idPublic}`).pipe(
            map((res) => this._mapper.mapTo(res['data']))
        );
    }

    updateStatusUserByIdPublic(idPublic: string, status: boolean): Observable<SuccessVoid> {
        return this._http.put<SuccessVoid>(`${Endpoints.user}/${idPublic}/status`, { status: status }).pipe(
            map(() => new SuccessVoid())
        );
    }

    updateUserByIdPublic(param: UserEntity, idPublic: string): Observable<SuccessVoid> {
        return this._http.put<SuccessVoid>(`${Endpoints.user}/${idPublic}`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }

    saveUser(param: UserEntity): Observable<SuccessVoid> {
        return this._http.post<SuccessVoid>(`${Endpoints.user}`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }

    updateUserInternoByIdPublic(param: UserEntity, idPublic: string): Observable<SuccessVoid> {
        return this._http.put<SuccessVoid>(`${Endpoints.user}/interno/${idPublic}`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }
}