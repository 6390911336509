import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { AuxiliarEntity } from '@domain/entities/auxiliar/auxiliar.entity';
import { KeyAuxiliarEnum } from '@shared/enums/key-auxiliar.enum';
import { AuxiliarRepository } from '@domain/repositories/auxiliar/auxiliar.repository';

@Injectable()
export class GetAllAuxiliarByChaveUseCase implements Usecase<KeyAuxiliarEnum, AuxiliarEntity[]> {

    constructor(
        private readonly _repository: AuxiliarRepository
    ) { }

    execute(chave: KeyAuxiliarEnum): Observable<AuxiliarEntity[] | Failure> {
        return this._repository.getAllAuxiliarByChave(chave).pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<AuxiliarEntity[]>) => res.right)
        );
    }
}