import { Injectable } from '@angular/core';

import { DocumentacaoModel } from './documentacao.model';
import { AuxiliarMapper } from '../auxiliar/auxiliar.mapper';
import { Mapper } from "@data/contracts/mapper.contract";
import { DocumentacaoEntity } from '@domain/entities/documentacao/documentacao.entity';

@Injectable()
export class DocumentacaoMapper implements Mapper<DocumentacaoModel, DocumentacaoEntity> {

    constructor(
        private readonly _mapper: AuxiliarMapper,
    ) { }

    mapFrom(param: DocumentacaoEntity): DocumentacaoModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            descricao: param?.descricao?.toUpperCase(),
            tipo_documento: this._mapper.mapFrom(param?.tipoDocumento),
            obrigatorio: param?.obrigatorio,
            status: param?.status,
        };
    }

    mapTo(param: DocumentacaoModel): DocumentacaoEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            descricao: param?.descricao?.toUpperCase(),
            tipoDocumento: this._mapper.mapTo(param?.tipo_documento),
            obrigatorio: param?.obrigatorio,
            status: param?.status,
        };
    }
}