import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { CategoriaRepository } from '@domain/repositories/categoria/categoria.repository';
import { CategoriaEntity } from '@domain/entities/categoria/categoria.entity';

@Injectable()
export class GetCategoriaByIdPublicUseCase implements Usecase<string, CategoriaEntity> {

    constructor(
        private readonly _repository: CategoriaRepository
    ) { }

    execute(idPublic: string): Observable<CategoriaEntity | Failure> {
        return this._repository.getCategoriaByIdPublic(idPublic).pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<CategoriaEntity>) => res.right)
        );
    }
}