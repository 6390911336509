import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { TurmaRepository } from '@domain/repositories/turma/turma.repository';
import { TurmaEntity } from '@domain/entities/turma/turma.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorParam } from '@shared/utils/paginator';

@Injectable()
export class GetAllTurmaPaginatedUseCase implements Usecase<PaginatorParam, PaginatorEntity<TurmaEntity[]>> {

    constructor(
        private readonly _repository: TurmaRepository
    ) { }

    execute(params: PaginatorParam): Observable<PaginatorEntity<TurmaEntity[]> | Failure> {
        return this._repository.getAllTurmaPaginated(params).pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<PaginatorEntity<TurmaEntity[]>>) => res.right)
        );
    }
}