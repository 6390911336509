import { FuseNavigation } from "@fuse/types";

import { Endpoints } from "@infra/constants/endpoints/endpoints";
import { PermissaoEnum } from "@shared/enums/permissao.enum";

export const MENU : FuseNavigation[] = [
    {
        id: 'DEFAULT',
        title: 'Ambiente Virtual',
        type: 'item',
        icon: 'ondemand_video',
        url: Endpoints.urlAva,
        externalUrl: true
    },
    {
        id: 'DEFAULT',
        title: 'Meu perfil',
        type: 'item',
        icon: 'account_circle',
        url: '/area-usuario'
    },
    // {
    //     id: PermissaoEnum.LISTAR_ORGAO,
    //     title: 'Órgãos',
    //     type: 'item',
    //     icon: 'business',
    //     url: '/orgao'
    // },
    {
        id: PermissaoEnum.LISTAR_PROFESSOR,
        title: 'Professores',
        type: 'item',
        icon: 'supervisor_account',
        url: '/professor'
    },
    {
        id: PermissaoEnum.VISUALIZAR_AREA_ACADEMICA,
        title: 'Acadêmico',
        type: 'collapsable',
        icon: 'chevron_right',
        children: [
            {
                id: PermissaoEnum.LISTAR_CATEGORIA,
                title: 'Áreas',
                type: 'item',
                icon: 'chevron_right',
                url: '/academico/categoria'
            },
            {
                id: PermissaoEnum.LISTAR_CURSO,
                title: 'Cursos',
                type: 'item',
                icon: 'chevron_right',
                url: '/academico/curso'
            },
            {
                id: PermissaoEnum.LISTAR_TURMA,
                title: 'Turmas',
                type: 'item',
                icon: 'chevron_right',
                url: '/academico/turma'
            }
        ]
    },
    {
        id: PermissaoEnum.VISUALIZAR_CONFIGURACAO,
        title: 'Configurações',
        type: 'collapsable',
        icon: 'chevron_right',
        children: [
            {
                id: PermissaoEnum.LISTAR_DOCUMENTACAO,
                title: 'Documentação',
                type: 'item',
                icon: 'chevron_right',
                url: 'configuracao/documentacao'
            },
            {
                id: PermissaoEnum.LISTAR_CURSO_MOODLE,
                title: 'Curso moodle',
                type: 'item',
                icon: 'chevron_right',
                url: 'configuracao/curso-moodle'
            },
            // {
            //     id: PermissaoEnum.LISTAR_AUXILIAR,
            //     title: 'Auxiliares',
            //     type: 'item',
            //     icon: 'chevron_right',
            //     url: 'configuracao/auxiliar'
            // },
            // {
            //     id: PermissaoEnum.LISTAR_PERFIL,
            //     title: 'Perfis',
            //     type: 'item',
            //     icon: 'chevron_right',
            //     url: 'configuracao/perfil'
            // },
            // {
            //     id: PermissaoEnum.LISTAR_PERMISSAO,
            //     title: 'Permissões',
            //     type: 'item',
            //     icon: 'chevron_right',
            //     url: 'configuracao/permissao'
            // }
        ]
    },
    {
        id: PermissaoEnum.VISUALIZAR_CURSO,
        title: 'Cursos',
        type: 'collapsable',
        icon: 'chevron_right',
        children: [
            // {
            //     id: PermissaoEnum.LISTAR_CURSO_PRESENCIAL,
            //     title: 'Presenciais',
            //     type: 'item',
            //     icon: 'chevron_right',
            //     url: '/curso/presencial'
            // },
            {
                id: PermissaoEnum.LISTAR_CURSO_EAD,
                title: 'EaD',
                type: 'item',
                icon: 'chevron_right',
                url: '/curso/ead'
            },
            {
                id: PermissaoEnum.LISTAR_CURSO_ENCERRADO,
                title: 'Encerrados',
                type: 'item',
                icon: 'chevron_right',
                url: '/curso/encerrado'
            },
            {
                id: PermissaoEnum.LISTAR_MEU_CURSO,
                title: 'Meus cursos',
                type: 'item',
                icon: 'chevron_right',
                url: '/curso/meu-curso'
            }
        ]
    },
    {
        id: PermissaoEnum.VISUALIZAR_USUARIO,
        title: 'Usuários',
        type: 'collapsable',
        icon: 'chevron_right',
        children: [
            {
                id: PermissaoEnum.LISTAR_ALUNO,
                title: 'Alunos',
                type: 'item',
                icon: 'school',
                url: '/usuario/aluno'
            },
            {
                id: PermissaoEnum.LISTAR_USUARIO,
                title: 'Usuários interno',
                type: 'item',
                icon: 'supervisor_account',
                url: '/usuario/interno'
            },
        ]
    },
    // {
    //     id: PermissaoEnum.CADASTRAR_CREDENCIAMENTO,
    //     title: 'Credenciamento como instrutor',
    //     type: 'item',
    //     icon: 'chevron_right',
    //     url: '/professor/credenciamento'
    // },
]