import { PhonePipe } from './phone/phone.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from './truncate/truncate.pipe';



@NgModule({
    declarations: [
        PhonePipe,
        TruncatePipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        PhonePipe,
        TruncatePipe
    ]
})
export class PipesModule { }
