import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { CategoriaRepository } from '@domain/repositories/categoria/categoria.repository';
import { CategoriaEntity } from '@domain/entities/categoria/categoria.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorParam } from '@shared/utils/paginator';

@Injectable()
export class GetAllCategoriaPaginatedUseCase implements Usecase<PaginatorParam, PaginatorEntity<CategoriaEntity[]>> {

    constructor(
        private readonly _repository: CategoriaRepository
    ) { }

    execute(params: PaginatorParam): Observable<PaginatorEntity<CategoriaEntity[]> | Failure> {
        return this._repository.getAllCategoriaPaginated(params).pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<PaginatorEntity<CategoriaEntity[]>>) => res.right)
        );
    }
}