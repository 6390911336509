import { Injectable } from '@angular/core';

import { CategoriaModel } from './categoria.model';
import { Mapper } from "@data/contracts/mapper.contract";
import { CategoriaEntity } from '@domain/entities/categoria/categoria.entity';

@Injectable()
export class CategoriaMapper implements Mapper<CategoriaModel, CategoriaEntity> {
    mapFrom(param: CategoriaEntity): CategoriaModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            nome: param?.nome,
            descricao: param?.descricao,
            status: param?.status,
        };
    }

    mapTo(param: CategoriaModel): CategoriaEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            nome: param?.nome,
            descricao: param?.descricao,
            status: param?.status,
        };
    }
}