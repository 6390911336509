import { Injectable } from '@angular/core';

import { CursoModel } from './curso.model';
import { AuxiliarMapper } from '../auxiliar/auxiliar.mapper';
import { CursoMoodleMapper } from '../curso-moodle/curso-moodle.mapper';
import { CategoriaMapper } from '../categoria/categoria.mapper';
import { Mapper } from "@data/contracts/mapper.contract";
import { CursoEntity } from '@domain/entities/curso/curso.entity';

@Injectable()
export class CursoMapper implements Mapper<CursoModel, CursoEntity> {

    constructor(
        private readonly _mapperAuxiliar: AuxiliarMapper,
        private readonly _mapperCursoMoodle: CursoMoodleMapper,
        private readonly _mapperCategoria: CategoriaMapper,
    ) { }

    mapFrom(param: CursoEntity): CursoModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            codigo: param?.codigo,
            nome: param?.nome?.toUpperCase(),
            carga_horaria: param?.cargaHoraria,
            precisa_deferimento: param?.precisaDeferimento,
            status: param?.status,
            modalidade: this._mapperAuxiliar.mapFrom(param?.modalidade),
            publico_alvo: this._mapperAuxiliar.mapFrom(param?.publicoAlvo),
            curso_moodle: this._mapperCursoMoodle.mapFrom(param?.cursoMoodle),
            categoria: this._mapperCategoria.mapFrom(param?.categoria),
            objetivo: param?.objetivo,
            detalhe_publico: param?.detalhePublico,
            ementa: param?.ementa,
            descricao: param?.descricao,
        };
    }

    mapTo(param: CursoModel): CursoEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            codigo: param?.codigo,
            nome: param?.nome?.toUpperCase(),
            cargaHoraria: param?.carga_horaria,
            precisaDeferimento: param?.precisa_deferimento,
            status: param?.status,
            modalidade: this._mapperAuxiliar.mapTo(param?.modalidade),
            publicoAlvo: this._mapperAuxiliar.mapTo(param?.publico_alvo),
            cursoMoodle: this._mapperCursoMoodle.mapTo(param?.curso_moodle),
            categoria: this._mapperCategoria.mapTo(param?.categoria),
            objetivo: param?.objetivo,
            detalhePublico: param?.detalhe_publico,
            ementa: param?.ementa,
            descricao: param?.descricao,
        };
    }
}