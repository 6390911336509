import { Injectable } from '@angular/core';

import { UserModel } from './user.model';
import { PerfilMapper } from '../perfil/perfil.mapper';
import { PermissaoMapper } from '../permissao/permissao.mapper';
import { PessoaMapper } from '../pessoa/pessoa.mapper';
import { Mapper } from "@data/contracts/mapper.contract";
import { UserEntity } from '@domain/entities/user/user.entity';

import { phoneFormat } from '@shared/utils/utils';

@Injectable()
export class UserMapper implements Mapper<UserModel, UserEntity> {
    
    constructor(
        private readonly _mapperPermissao: PermissaoMapper,
        private readonly _mapperPerfil: PerfilMapper,
        private readonly _mapperPessoa: PessoaMapper,
    ) { }

    mapFrom(param: UserEntity): UserModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            nome: param?.nome?.toUpperCase(),
            username: param?.username?.toLowerCase(),
            email: param?.email?.toLowerCase(),
            password: param?.password,
            pessoa: this._mapperPessoa.mapFrom(param?.pessoa),
            perfil:  this._mapperPerfil.mapFrom(param?.perfil),
            status: param?.status,
            expirado: param?.expirado,
            habilitado: param?.habilitado,
            primeiro_acesso: param?.primeiroAcesso,
            telefone: param?.telefone?.toString()?.replace(/\D/g, ''),

            permissoes: param?.permissoes?.map(permissao => this._mapperPermissao.mapFrom(permissao))
        };
    }

    mapTo(param: UserModel): UserEntity {        
        return {
            id: param?.id,
            idPublic: param?.id_public,
            nome: param?.nome?.toUpperCase(),
            username: param?.username?.toLowerCase(),
            email: param?.email?.toLowerCase(),
            password: param?.password,
            pessoa: this._mapperPessoa.mapTo(param?.pessoa),
            perfil:  this._mapperPerfil.mapTo(param?.perfil),
            status: param?.status,
            expirado: param?.expirado,
            habilitado: param?.habilitado,
            primeiroAcesso: param?.primeiro_acesso,
            telefone: phoneFormat(param?.telefone),

            permissoes: param?.permissoes?.map(permissao => this._mapperPermissao.mapTo(permissao)) ?? []
        };
    }

    /**
     * Dados que serão enviados para o back-end, para realizar o cadastro da pessoa.
     * 
     * @param param entidade de usuário
     * @returns objeto modelo de usuario para cadastro externo
     */
    saveUserExterno(param: UserEntity): any {
        const pessoa = this._mapperPessoa.mapFrom(param?.pessoa);

        return {
            username: param?.username?.toLowerCase(),
            email: param?.email?.toLowerCase(),
            email_confirm: param?.email?.toLowerCase(),
            password: param?.password,
            password_confirm: param?.password,
            nome: pessoa.nome,
            pessoa: {
                ...pessoa
            },
            // formacao: pessoa.formacao.id,
            // estado_civil: pessoa.estado_civil.id,
            // sexo: pessoa.sexo.id,
            // orgao: pessoa.orgao.id,
        };
    }
}