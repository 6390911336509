import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { TurmaRemoteDataSource } from "@data/data-source/turma/turma-remote.datasource";
import { TurmaRepository } from "@domain/repositories/turma/turma.repository";
import { TurmaEntity } from "@domain/entities/turma/turma.entity";
import { InscricaoEntity } from "@domain/entities/inscricao/inscricao.entity";
import { PaginatorEntity } from "@domain/entities/paginator/paginator.entity";
import { Failure } from '@infra/contracts/failure.contract';
import { SuccessVoid } from "@infra/success/success";
import { PaginatorParam } from "@shared/utils/paginator";

@Injectable()
export class TurmaRepositoryImpl implements TurmaRepository {

    constructor(
        private readonly _remoteDataSource: TurmaRemoteDataSource,
    ) { }

    getAllTurmaPaginated(params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<TurmaEntity[]>>> {
        return this._remoteDataSource.getAllTurmaPaginated(params).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getAllTurmaStatusAtivo(): Observable<Either<Failure, TurmaEntity[]>> {
        return this._remoteDataSource.getAllTurmaStatusAtivo().pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getTurmaByIdPublic(idPublic: string): Observable<Either<Failure, TurmaEntity>> {
        return this._remoteDataSource.getTurmaByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    updateStatusTurmaByIdPublic(idPublic: string, status: boolean): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateStatusTurmaByIdPublic(idPublic, status).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    updateTurmaByIdPublic(param: TurmaEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateTurmaByIdPublic(param, idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    saveTurma(param: TurmaEntity): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.saveTurma(param).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    saveTurmaInscricao(param: InscricaoEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.saveTurmaInscricao(param, idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }
}