import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { InscricaoRepository } from '@domain/repositories/inscricao/inscricao.repository';
import { InscricaoEntity } from '@domain/entities/inscricao/inscricao.entity';

@Injectable()
export class GetMyInscricaoByIdPublicUseCase implements Usecase<string, InscricaoEntity> {

    constructor(
        private readonly _repository: InscricaoRepository
    ) { }

    execute(idPublic: string): Observable<InscricaoEntity | Failure> {
        return this._repository.getMyInscricaoByIdPublic(idPublic).pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<InscricaoEntity>) => res.right)
        );
    }
}