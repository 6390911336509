import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProfessorRemoteDataSource } from './professor-remote.datasource';
import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { SuccessVoid } from '@infra/success/success';
import { ProfessorMapper } from '@data/models/professor/professor.mapper';
import { ProfessorEntity } from '@domain/entities/professor/professor.entity';
import { ProfessorModel } from '@data/models/professor/professor.model';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorMapper } from '@data/models/paginator/paginator.mapper';
import { PaginatorParam } from "@shared/utils/paginator";
import { HttpUtils } from '@shared/utils/http-utils';

@Injectable()
export class ProfessorRemoteDataSourceImpl implements ProfessorRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: ProfessorMapper,
        private readonly _mapperPaginator: PaginatorMapper<ProfessorEntity[]>,
    ) { }

    getAllProfessorStatusAtivo(): Observable<ProfessorEntity[]> {
        return this._http.get<ProfessorModel[]>(`${Endpoints.professor}/ativo`).pipe(
            map(res => res['data']?.map(professor => this._mapper.mapTo(professor)))
        );
    }    

    getMeuCredenciamentoProfessor(): Observable<ProfessorEntity> {
        return this._http.get<ProfessorModel>(`${Endpoints.professor}/meu-credenciamento`).pipe(
            map((res) => this._mapper.mapTo(res['data']))
        );
    }
    
    /**
     * Busca todos os professores.
     * 
     * @param page number
     * @param size number
     * @param search string | null
     * @returns Observable<PaginatorEntity<ProfessorEntity[]>>
     */
    getAllProfessorPaginated(params: PaginatorParam): Observable<PaginatorEntity<ProfessorEntity[]>> {
        return this._http.get<ProfessorModel[]>(`${Endpoints.professor}`, { params: HttpUtils.buildParams(params.mapTo()) })
        .pipe(
            map((res) => this._mapperPaginator.mapPaging(
                res['data']?.map(professor => this._mapper.mapTo(professor)), 
                res['meta']
                )
            )
        );
    }

    getProfessorByIdPublic(idPublic: string): Observable<ProfessorEntity> {
        return this._http.get<ProfessorModel>(`${Endpoints.professor}/${idPublic}`).pipe(
            map((res) => this._mapper.mapTo(res['data']))
        );
    }

    updateStatusProfessorByIdPublic(idPublic: string, status: boolean): Observable<SuccessVoid> {
        return this._http.put<SuccessVoid>(`${Endpoints.professor}/${idPublic}/status`, { status: status }).pipe(
            map(() => new SuccessVoid())
        );
    }

    updateProfessorByIdPublic(param: ProfessorEntity, idPublic: string): Observable<SuccessVoid> {
        return this._http.put<SuccessVoid>(`${Endpoints.professor}/${idPublic}`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }

    updateProfessorCredenciamento(param: ProfessorEntity): Observable<SuccessVoid> {
        return this._http.put<SuccessVoid>(`${Endpoints.professor}/avaliar-credencial`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }

    saveProfessor(param: ProfessorEntity): Observable<SuccessVoid> {
        const dataSubmit = HttpUtils.jsonToFormData(this._mapper.mapFrom(param));
        return this._http.post<SuccessVoid>(`${Endpoints.professor}`, dataSubmit).pipe(
            map(() => new SuccessVoid())
        );
    }

    deleteProfessorByIdPublic(idPublic: string): Observable<SuccessVoid> {
        return this._http.delete(`${Endpoints.professor}/${idPublic}`).pipe(
            map(() => new SuccessVoid())
        );
    }
}