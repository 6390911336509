import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { InscricaoRemoteDataSource } from './inscricao-remote.datasource';
import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { SuccessVoid } from '@infra/success/success';
import { InscricaoModel } from '@data/models/inscricao/inscricao.model';
import { InscricaoEntity } from '@domain/entities/inscricao/inscricao.entity';
import { InscricaoMapper } from '@data/models/inscricao/inscricao.mapper';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorMapper } from '@data/models/paginator/paginator.mapper';
import { PaginatorParam } from '@shared/utils/paginator';
import { HttpUtils } from '@shared/utils/http-utils';

@Injectable()
export class InscricaoRemoteDataSourceImpl implements InscricaoRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: InscricaoMapper,
        private readonly _mapperPaginator: PaginatorMapper<InscricaoEntity[]>,
    ) { }

    getAllInscricaoByTurmaPaginated(idPublic: string, params: PaginatorParam): Observable<PaginatorEntity<InscricaoEntity[]>> {
        return this._http.get<InscricaoModel[]>(`${Endpoints.turma}/inscricoes/${idPublic}`, { params: HttpUtils.buildParams(params.mapTo()) })
        .pipe(
            map((res) => this._mapperPaginator.mapPaging(
                res['data']?.map(inscricao => this._mapper.mapTo(inscricao)), 
                res['meta']
                )
            )
        );
    }

    getAllMyInscricaoPaginated(params: PaginatorParam): Observable<PaginatorEntity<InscricaoEntity[]>> {
        return this._http.get<InscricaoModel[]>(`${Endpoints.inscricao}/aluno-inscricoes`, { params: HttpUtils.buildParams(params.mapTo()) })
        .pipe(
            map((res) => this._mapperPaginator.mapPaging(
                res['data']?.map(inscricao => this._mapper.mapTo(inscricao)), 
                res['meta']
                )
            )
        );
    }

    getMyInscricaoByIdPublic(idPublic: string): Observable<InscricaoEntity> {
        return this._http.get<InscricaoEntity>(`${Endpoints.inscricao}/minha-inscricao/${idPublic}`).pipe(
            map((res) => this._mapper.mapTo(res['data']))
        );
    }

    deleteInscricaoByIdPublic(idPublic: string, minhaInscricao?: boolean): Observable<SuccessVoid> {
        return this._http.delete<InscricaoEntity>(`${Endpoints.inscricao}/delete-inscricao/${idPublic}`).pipe(
            map(() => new SuccessVoid())
        );
    }

    redefinirInscricaoByIdPublic(idPublic: string): Observable<SuccessVoid> {
        return this._http.post<InscricaoEntity>(`${Endpoints.turma}/inscricao/zerar/${idPublic}`, {}).pipe(
            map(() => new SuccessVoid())
        );
    }

    notificacaoInscricaoByIdPublic(idPublic: string): Observable<SuccessVoid> {
        return this._http.post<InscricaoEntity>(`${Endpoints.turma}/inscricao/notificacao/${idPublic}`, {}).pipe(
            map(() => new SuccessVoid())
        );
    }

    deferimentoInscricaoByIdPublic(idPublic: string, status: boolean): Observable<SuccessVoid> {
        return this._http.post<InscricaoEntity>(`${Endpoints.inscricao}/${idPublic}/status`, { status: status }).pipe(
            map(() => new SuccessVoid())
        );
    }

    getMyInscricaoWithTermoByIdPublic(idPublic: string): Observable<InscricaoEntity> {
        return this._http.get<InscricaoEntity>(`${Endpoints.inscricao}/minhas-inscricoes/${idPublic}/termo-ciencia`).pipe(
            map((res) => this._mapper.mapTo(res['data']))
        );
    }

    updateTermoMyInscricaoByIdPublic(idPublic: string): Observable<SuccessVoid> {
        return this._http.post<InscricaoEntity>(`${Endpoints.inscricao}/minhas-inscricoes/${idPublic}/termo-ciencia`, {}).pipe(
            map(() => new SuccessVoid())
        );
    }

    getDocumentoVinculoInscricaoByIdPublic(idPublic: string): Observable<Blob> {
        return this._http.get(`${Endpoints.inscricao}/documento-vinculados/${idPublic}`, { 
            headers: new HttpHeaders({
                'Content-Type': 'application/pdf',
            }), 
            responseType: 'blob' 
        }).pipe(
            map((res) => res)
        );
    }
}