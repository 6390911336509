import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { InscricaoRemoteDataSource } from "@data/data-source/inscricao/inscricao-remote.datasource";
import { InscricaoRepository } from "@domain/repositories/inscricao/inscricao.repository";
import { InscricaoEntity } from "@domain/entities/inscricao/inscricao.entity";
import { PaginatorEntity } from "@domain/entities/paginator/paginator.entity";
import { Failure } from '@infra/contracts/failure.contract';
import { SuccessVoid } from "@infra/success/success";
import { PaginatorParam } from "@shared/utils/paginator";

@Injectable()
export class InscricaoRepositoryImpl implements InscricaoRepository {

    constructor(
        private readonly _remoteDataSource: InscricaoRemoteDataSource,
    ) { }

    getAllInscricaoByTurmaPaginated(idPublic: string, params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<InscricaoEntity[]>>> {
        return this._remoteDataSource.getAllInscricaoByTurmaPaginated(idPublic, params).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getAllMyInscricaoPaginated(params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<InscricaoEntity[]>>> {
        return this._remoteDataSource.getAllMyInscricaoPaginated(params).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getMyInscricaoByIdPublic(idPublic: string): Observable<Either<Failure, InscricaoEntity>> {
        return this._remoteDataSource.getMyInscricaoByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    deleteInscricaoByIdPublic(idPublic: string, minhaInscricao?: boolean): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.deleteInscricaoByIdPublic(idPublic, minhaInscricao).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    redefinirInscricaoByIdPublic(idPublic: string): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.redefinirInscricaoByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    notificacaoInscricaoByIdPublic(idPublic: string): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.notificacaoInscricaoByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    deferimentoInscricaoByIdPublic(idPublic: string, status: boolean): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.deferimentoInscricaoByIdPublic(idPublic, status).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getMyInscricaoWithTermoByIdPublic(idPublic: string): Observable<Either<Failure, InscricaoEntity>> {
        return this._remoteDataSource.getMyInscricaoWithTermoByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    updateTermoMyInscricaoByIdPublic(idPublic: string): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateTermoMyInscricaoByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getDocumentoVinculoInscricaoByIdPublic(idPublic: string): Observable<Either<Failure, Blob>> {
        return this._remoteDataSource.getDocumentoVinculoInscricaoByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map((res) => right(res)),
        );
    }
}