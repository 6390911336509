import { Observable } from 'rxjs';
import { AuthEntity } from '@domain/entities/auth/auth.entity';
import { SuccessVoid } from '@infra/success/success';

export abstract class AuthUserRemoteDataSource {
    abstract login(param: AuthEntity): Observable<SuccessVoid>;
    abstract logout(): Observable<SuccessVoid>;

    abstract forgotPassword(param: AuthEntity): Observable<SuccessVoid>;
    abstract resetPassword(param: AuthEntity): Observable<SuccessVoid>;
    abstract changePassword(param: AuthEntity): Observable<SuccessVoid>;
}