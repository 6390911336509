import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { UserRepository } from '@domain/repositories/user/user.repository';
import { Failure } from '@infra/contracts/failure.contract';
import { UserEntity } from '@domain/entities/user/user.entity';
import { PaginatorEntity } from "@domain/entities/paginator/paginator.entity";
import { UserRemoteDataSource } from '@data/data-source/user/user-remote.datasource';
import { SuccessVoid } from "@infra/success/success";
import { PaginatorParam } from "@shared/utils/paginator";

@Injectable()
export class UserRepositoryImpl implements UserRepository {

    constructor(
        private readonly _remoteDataSource: UserRemoteDataSource,
    ) { }

    getUserAuth(): Observable<Either<Failure, UserEntity>> {
        return this._remoteDataSource.getUserAuth().pipe(
            catchError((error) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    saveUserExterno(param: UserEntity): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.saveUserExterno(param).pipe(
            catchError((error) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getAllUserPaginated(params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<UserEntity[]>>> {
        return this._remoteDataSource.getAllUserPaginated(params).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getAllUserStatusAtivo(): Observable<Either<Failure, UserEntity[]>> {
        return this._remoteDataSource.getAllUserStatusAtivo().pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getUserByIdPublic(idPublic: string): Observable<Either<Failure, UserEntity>> {
        return this._remoteDataSource.getUserByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    updateStatusUserByIdPublic(idPublic: string, status: boolean): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateStatusUserByIdPublic(idPublic, status).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    updateUserByIdPublic(param: UserEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateUserByIdPublic(param, idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    saveUser(param: UserEntity): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.saveUser(param).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    updateUserInternoByIdPublic(param: UserEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateUserInternoByIdPublic(param, idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }
}