import { Observable } from 'rxjs';

import { TurmaEntity } from '@domain/entities/turma/turma.entity';
import { InscricaoEntity } from '@domain/entities/inscricao/inscricao.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { SuccessVoid } from '@infra/success/success';
import { PaginatorParam } from '@shared/utils/paginator';

export abstract class TurmaRemoteDataSource {
    abstract getAllTurmaStatusAtivo(): Observable<TurmaEntity[]>;
    abstract getAllTurmaPaginated(params: PaginatorParam): Observable<PaginatorEntity<TurmaEntity[]>>;
    abstract getTurmaByIdPublic(idPublic: string): Observable<TurmaEntity>;
    abstract updateStatusTurmaByIdPublic(idPublic: string, status: boolean): Observable<SuccessVoid>;
    abstract updateTurmaByIdPublic(param: TurmaEntity, idPublic: string): Observable<SuccessVoid>;
    abstract saveTurma(param: TurmaEntity): Observable<SuccessVoid>;
    abstract saveTurmaInscricao(param: InscricaoEntity, idPublic: string): Observable<SuccessVoid>;
}