import { StepperSelectionEvent } from "@angular/cdk/stepper";
import { EventEmitter } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { MatHorizontalStepper } from "@angular/material/stepper";

import Swal from "sweetalert2";

import { FormControlValidatorContract } from "../contracts/form-control-default.contract";
import { Subject } from "rxjs";
import { clearValue, sumElementsByMultipliers, sumToDV } from "./utils";

export class Helps {

    static generateNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;;
    }

    static stringAdicional(str: string, adicional: string, posicao: number): string {
        const parteInicial = str.slice(0, posicao);
        const parteFinal = str.slice(posicao);

        return parteInicial + adicional + parteFinal;
    }

    static jsonToFormData (json: Object, parentKey: string = ''): FormData {
        let objectJson = this.removerUndefinedAndEmptyObjects(json);

        return Object.entries(objectJson).reduce((formData: FormData, [key, value]) => {
            const appendToFormData = (data, parentKey = '') => {
                for (let innerKey in data) {
                    if (data.hasOwnProperty(innerKey)) {
                        const nestedKey = parentKey ? `${parentKey}[${innerKey}]` : innerKey;
                        const innerValue = data[innerKey] !== null ? data[innerKey] : '';

                        if(!(innerValue instanceof File) && (Array.isArray(innerValue) || (typeof innerValue === 'object' && innerValue !== null))) {
                            appendToFormData(innerValue, nestedKey);
                        } else formData.append(nestedKey, innerValue);
                    }
                }
            };

            appendToFormData({ [key]: value }, parentKey);

            return formData;
        }, new FormData());
    }

    /**
     * Realizar o tratamento de undefined do objeto, é possível retirar ou substiuir por outro valor
     * 
     * @param objeto 
     * @param defaultValor 
     * @returns 
     */
    static treatingUndefinedObject<T>(objeto: T | T[], defaultValor?: any) {
        if (Array.isArray(objeto))
            return objeto.map(elemento => this.treatingUndefinedObject(elemento, defaultValor));

        if (typeof objeto === 'object' && objeto !== null && !(objeto instanceof File)) {
            return Object.entries(objeto).reduce((propriedade: any, [chave, valor]) => {
                const novoValor = this.treatingUndefinedObject(valor, defaultValor);
                if (novoValor !== undefined)
                    propriedade[chave] = novoValor;

                if(novoValor === undefined && defaultValor !== undefined)
                    propriedade[chave] = defaultValor;
                
                return propriedade;
            }, {});
        }

        return objeto;
    }

    static removerUndefinedAndEmptyObjects<T>(objeto: T | T[], defaultValor?: any) {
        if (Array.isArray(objeto))
            return objeto.map(elemento => this.treatingUndefinedObject(elemento, defaultValor));

        return Object.entries(objeto).reduce((acc, [key, value]) => {
            if (value === undefined) return acc;
            if (value !== null && typeof value === 'object' && !(value instanceof File)) {
                const cleanedValue = this.removerUndefinedAndEmptyObjects(value, defaultValor);
                if (Object.keys(cleanedValue).length > 0)
                    acc[key] = cleanedValue;

                return acc;
            }

            acc[key] = value;
            return acc;
        }, {});
    }

    /**
     * Retorna true caso seja inválido.
     * 
     * Um exemplo de como usar `markFormTouched`:
     * 
     * ```
     * const formOrgao = new FormControl(null, Validators.required);
     * markFormTouched(0, [formOrgao])
     * ```
     * 
     * -OR-
     * 
     * ```
     * const formCadastro: FormControl = new FormControl(null, Validators.required);
     * const formCadastro2: FormControl = new FormControl(null, Validators.required);
     * const formOrgao: FormControl = new FormControl(null);
     * const orgaoValidation$: Subject<boolean> = new Subject<boolean>(false);
     * markFormTouched(0, [formCadastro, [formOrgao, orgaoValidation$], formCadastro2])
     * ```
     * 
     * -OBS-
     * 
     * Quando o formulário tiver mais de um item a ser validado, utilizando os componentes
     * customizados, passe dessa froma:
     * ```
     * const orgaoValidation2$: Subject<boolean> = new Subject<boolean>(false);
     * markFormTouched(0, [formCadastro, [formOrgao, orgaoValidation$, orgaoValidation2$], formCadastro2])
     * ```
     * 
     * @param step (EventEmitter<StepperSelectionEvent> | number)
     * @param formControlOrSubject (AbstractControl | Subject<boolean> | [AbstractControl, ...(Subject<boolean>|AbstractControl)[]])[]
     * @returns boolean
     */
    static markFormTouched(
        step: EventEmitter<StepperSelectionEvent> | number, 
        formControlOrSubject: (AbstractControl | Subject<boolean> | [AbstractControl, ...(Subject<boolean>|AbstractControl)[]])[],
        stepper: MatHorizontalStepper = null
    ): boolean {
        let isInvalid = false;

        if(typeof step === 'number' && formControlOrSubject[step]) {
            let isArray = Array.isArray(formControlOrSubject[step]);
            let data: any = isArray ? formControlOrSubject[step] : [formControlOrSubject[step]];

            data.forEach(formSub => {
                if(formSub instanceof AbstractControl) {
                    let form = formSub as AbstractControl;
                    form.markAllAsTouched();
                    if(form.invalid) isInvalid = true;
                }

                if(formSub instanceof Subject) {
                    let sub = formSub as Subject<boolean>;
                    sub.next(true);
                } 
            });
        }

        if(isInvalid) {
            let label = 'Preencha todos os campos destacado de vermelho';
            if(typeof step === 'number' && stepper) {
                stepper.selectedIndex = step;
                let info = stepper.steps.toArray();
                if(info[step]?.label) label +=  ` em <b>${info[step]?.label?.toLowerCase()}</b>`;
            }

            Swal.fire('Dados incompletos', label, 'error');
        }

        return isInvalid;
    }

    /**
     * É utilizado mais para quando for feito alguma alteração e deseja fazer essa ações de uma única vez.
     * 
     * @param form AbstractControl
     * @param formControl FormControlValidatorContract[]
     * @param disabled boolean, desabilita todos, caso seja true.
     */
    static toggleFormControls(form: AbstractControl, formControl: FormControlValidatorContract[], disabled: boolean = false): void {
        formControl.forEach((control, idx) => {
            const formControlData = control?.name ? form.get(control.name) : form;
            
            if(disabled || control?.disabled)
                formControlData?.disable();
            else 
                formControlData?.enable();

            if(control?.options)
                formControlData.setValidators(control?.options);

            if(control.value !== undefined)
                formControlData.setValue(control.value);

            if(control.clearValidators)
                formControlData.clearValidators();

            formControlData.updateValueAndValidity();
        });
    }

    static isPisPasep(value: string | number): boolean {
        let valueFiltrado = String(value).replace(/\D/g, '');
        if(!value || valueFiltrado.length > 11 || String(value) !== valueFiltrado) return false;

        const pis = clearValue(value, 11, {
            fillZerosAtLeft: true,
            rejectHigherLength: true,
            rejectEqualSequence: true,
        });
        
        const dv = String(sumToDV(
            sumElementsByMultipliers(
                clearValue(pis, 10, {
                    trimAtRight: true,
                }), 
                [3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
            )
        ));

        if(dv !== pis.substring(10, 11)) return false;
        return true;
    }

    static validFilePdf(file: File | null, sizeMaxMb = 10): boolean {
        if(!file) {
            Swal.fire('Arquivo 404', 'Não foi informado um arquivo', 'error');
            return false;
        }

        let [
            tipoArquivo, 
            sizeMB
        ] = [
            file.type.replace(/.*\//, ""),
            Number((file.size / (1024*1024)).toFixed(2))
        ];

        if (!tipoArquivo.match(/pdf/)) {
            Swal.fire('Formato inválido', 'Somente são aceitos arquivos no formato PDF', 'error');
            return false;
        }

        if (sizeMB > sizeMaxMb) {
            Swal.fire(`Tamanho Inválido`, `O tamanho máximo para envio deste arquivo é de ${sizeMaxMb} MB`, 'error');
            return false;
        }
        
        return true;
    }

}