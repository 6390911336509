import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { PessoaModel } from './pessoa.model';
import { AuxiliarMapper } from './../auxiliar/auxiliar.mapper';
import { EnderecoMapper } from './../endereco/endereco.mapper';
import { OrgaoMapper } from './../orgao/orgao.mapper';
import { Mapper } from "@data/contracts/mapper.contract";
import { PessoaEntity } from '@domain/entities/pessoa/pessoa.entity';

import { cnpjCpfFormat, phoneFormat } from '@shared/utils/utils';

@Injectable()
export class PessoaMapper implements Mapper<PessoaModel, PessoaEntity> {

    constructor(
        private readonly _datePipe: DatePipe,
        private readonly _mapperAuxiliar: AuxiliarMapper,
        private readonly _mapperEndereco: EnderecoMapper,
        private readonly _mapperOrgao: OrgaoMapper,
    ) { }

    mapFrom(param: PessoaEntity): PessoaModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            nome: param?.nome?.trim()?.toUpperCase(),
            nome_social: param?.nomeSocial?.trim()?.toUpperCase(),
            cpf: param?.cpf?.toString()?.replace(/\D/g, ''),
            rg: param?.rg?.toString()?.replace(/\D/g, ''),
            profissao: this._mapperAuxiliar.mapFrom(param?.profissao),
            sexo: this._mapperAuxiliar.mapFrom(param?.sexo),
            formacao: this._mapperAuxiliar.mapFrom(param?.formacao),
            estado_civil: this._mapperAuxiliar.mapFrom(param?.estadoCivil),
            orgao: this._mapperOrgao.mapFrom(param?.orgao),
            celular: param?.celular?.toString()?.replace(/\D/g, ''),
            fone: param?.fone?.toString()?.replace(/\D/g, ''),
            data_nascimento: param?.dataNascimento ? this._datePipe.transform(param?.dataNascimento, 'yyyy-MM-dd') : undefined,
            lotacao: param?.lotacao,
            cargo: param?.cargo,
            funcao: param?.funcao,
            area_atuacao: param?.areaAtuacao,
            matricula: param?.matricula?.toString()?.replace(/\D/g, ''),
            nome_chefe: param?.nomeChefe?.trim(),
            email_chefe: param?.emailChefe?.toLowerCase(),
            servidor: param?.servidor,
            deficiencia: param?.deficiencia?.length > 0 ? param?.deficiencia?.map(deficiencia => this._mapperAuxiliar.mapFrom(deficiencia)) : [],
            endereco: this._mapperEndereco.mapFrom(param?.endereco),
        };
    }

    mapTo(param: PessoaModel): PessoaEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            nome: param?.nome?.trim()?.toUpperCase() || null,
            nomeSocial: param?.nome_social?.trim()?.toUpperCase() || null,
            cpf: cnpjCpfFormat(param?.cpf),
            rg: param?.rg,
            profissao: this._mapperAuxiliar.mapTo(param?.profissao),
            sexo: this._mapperAuxiliar.mapTo(param?.sexo),
            formacao: this._mapperAuxiliar.mapTo(param?.formacao),
            estadoCivil: this._mapperAuxiliar.mapTo(param?.estado_civil),
            orgao: this._mapperOrgao.mapTo(param?.orgao),
            celular:  phoneFormat(param?.celular) || null,
            fone: phoneFormat(param?.fone) || null,
            dataNascimento: this._datePipe.transform(param?.data_nascimento, 'yyyy-MM-dd'),
            lotacao: param?.lotacao,
            cargo: param?.cargo,
            funcao: param?.funcao,
            areaAtuacao: param?.area_atuacao,
            matricula: param?.matricula?.toString()?.replace(/\D/g, ''),
            nomeChefe: param?.nome_chefe?.trim(),
            emailChefe: param?.email_chefe?.toLowerCase(),
            servidor: param?.servidor,
            deficiencia: param?.deficiencia?.length > 0 ? param?.deficiencia?.map(deficiencia => this._mapperAuxiliar.mapTo(deficiencia)) : [],
            endereco: this._mapperEndereco.mapTo(param?.endereco),
        };
    }
}