import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { CategoriaRemoteDataSource } from "@data/data-source/categoria/categoria-remote.datasource";
import { CategoriaRepository } from "@domain/repositories/categoria/categoria.repository";
import { CategoriaEntity } from "@domain/entities/categoria/categoria.entity";
import { PaginatorEntity } from "@domain/entities/paginator/paginator.entity";
import { Failure } from '@infra/contracts/failure.contract';
import { SuccessVoid } from "@infra/success/success";
import { PaginatorParam } from "@shared/utils/paginator";

@Injectable()
export class CategoriaRepositoryImpl implements CategoriaRepository {

    constructor(
        private readonly _remoteDataSource: CategoriaRemoteDataSource,
    ) { }

    getAllCategoriaPaginated(params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<CategoriaEntity[]>>> {
        return this._remoteDataSource.getAllCategoriaPaginated(params).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getAllCategoriaStatusAtivo(): Observable<Either<Failure, CategoriaEntity[]>> {
        return this._remoteDataSource.getAllCategoriaStatusAtivo().pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getCategoriaByIdPublic(idPublic: string): Observable<Either<Failure, CategoriaEntity>> {
        return this._remoteDataSource.getCategoriaByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    updateStatusCategoriaByIdPublic(idPublic: string, status: boolean): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateStatusCategoriaByIdPublic(idPublic, status).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    updateCategoriaByIdPublic(param: CategoriaEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateCategoriaByIdPublic(param, idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    saveCategoria(param: CategoriaEntity): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.saveCategoria(param).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }
}