import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { DocumentacaoRepository } from '@domain/repositories/documentacao/documentacao.repository';
import { DocumentacaoEntity } from '@domain/entities/documentacao/documentacao.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorParam } from '@shared/utils/paginator';

@Injectable()
export class GetAllDocumentacaoPaginatedUseCase implements Usecase<PaginatorParam, PaginatorEntity<DocumentacaoEntity[]>> {

    constructor(
        private readonly _repository: DocumentacaoRepository
    ) { }

    execute(params: PaginatorParam): Observable<PaginatorEntity<DocumentacaoEntity[]> | Failure> {
        return this._repository.getAllDocumentacaoPaginated(params).pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<PaginatorEntity<DocumentacaoEntity[]>>) => res.right)
        );
    }
}