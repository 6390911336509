import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CategoriaRemoteDataSource } from './categoria-remote.datasource';
import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { SuccessVoid } from '@infra/success/success';
import { CategoriaMapper } from '@data/models/categoria/categoria.mapper';
import { CategoriaEntity } from '@domain/entities/categoria/categoria.entity';
import { CategoriaModel } from '@data/models/categoria/categoria.model';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorMapper } from '@data/models/paginator/paginator.mapper';
import { PaginatorParam } from "@shared/utils/paginator";
import { HttpUtils } from '@shared/utils/http-utils';

@Injectable()
export class CategoriaRemoteDataSourceImpl implements CategoriaRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: CategoriaMapper,
        private readonly _mapperPaginator: PaginatorMapper<CategoriaEntity[]>,
    ) { }

    getAllCategoriaStatusAtivo(): Observable<CategoriaEntity[]> {
        return this._http.get<CategoriaModel[]>(`${Endpoints.categoria}/ativo`).pipe(
            map(res => res['data']?.map(categoria => this._mapper.mapTo(categoria)))
        );
    }
    
    /**
     * Busca todas as categorias.
     * 
     * @param page number
     * @param size number
     * @param search string | null
     * @returns Observable<PaginatorEntity<CategoriaEntity[]>>
     */
    getAllCategoriaPaginated(params: PaginatorParam): Observable<PaginatorEntity<CategoriaEntity[]>> {
        return this._http.get<CategoriaModel[]>(`${Endpoints.categoria}`, { params: HttpUtils.buildParams(params.mapTo()) })
        .pipe(
            map((res) => this._mapperPaginator.mapPaging(
                res['data']?.map(categoria => this._mapper.mapTo(categoria)), 
                res['meta']
                )
            )
        );
    }

    getCategoriaByIdPublic(idPublic: string): Observable<CategoriaEntity> {
        return this._http.get<CategoriaEntity>(`${Endpoints.categoria}/${idPublic}`).pipe(
            map((res) => this._mapper.mapTo(res['data']))
        );
    }

    updateStatusCategoriaByIdPublic(idPublic: string, status: boolean): Observable<SuccessVoid> {
        return this._http.put<SuccessVoid>(`${Endpoints.categoria}/${idPublic}/status`, { status: status }).pipe(
            map(() => new SuccessVoid())
        );
    }

    updateCategoriaByIdPublic(param: CategoriaEntity, idPublic: string): Observable<SuccessVoid> {
        return this._http.put<SuccessVoid>(`${Endpoints.categoria}/${idPublic}`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }

    saveCategoria(param: CategoriaEntity): Observable<SuccessVoid> {
        return this._http.post<SuccessVoid>(`${Endpoints.categoria}`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }
}