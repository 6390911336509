import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { SuccessVoid } from '@infra/success/success';
import { OrgaoRepository } from '@domain/repositories/orgao/orgao.repository';
import { OrgaoEntity } from '@domain/entities/orgao/orgao.entity';

@Injectable()
export class SaveOrgaoUseCase implements Usecase<OrgaoEntity, SuccessVoid> {

    constructor(
        private readonly _repository: OrgaoRepository
    ) { }

    execute(param: OrgaoEntity): Observable<SuccessVoid | Failure> {
        return this._repository.saveOrgao(param).pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<SuccessVoid>) => res.right)
        );
    }
}