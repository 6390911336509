import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TurmaRemoteDataSource } from './turma-remote.datasource';
import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { SuccessVoid } from '@infra/success/success';
import { TurmaMapper } from '@data/models/turma/turma.mapper';
import { TurmaEntity } from '@domain/entities/turma/turma.entity';
import { TurmaModel } from '@data/models/turma/turma.model';
import { InscricaoEntity } from '@domain/entities/inscricao/inscricao.entity';
import { InscricaoMapper } from '@data/models/inscricao/inscricao.mapper';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorMapper } from '@data/models/paginator/paginator.mapper';
import { PaginatorParam } from '@shared/utils/paginator';
import { HttpUtils } from '@shared/utils/http-utils';

@Injectable()
export class TurmaRemoteDataSourceImpl implements TurmaRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: TurmaMapper,
        private readonly _mapperInscricao: InscricaoMapper,
        private readonly _mapperPaginator: PaginatorMapper<TurmaEntity[]>,
    ) { }

    getAllTurmaStatusAtivo(): Observable<TurmaEntity[]> {
        return this._http.get<TurmaModel[]>(`${Endpoints.turma}/ativo`).pipe(
            map(res => res['data']?.map(turma => this._mapper.mapTo(turma)))
        );
    }
    
    /**
     * Busca todas as turmas.
     * 
     * @param params PaginatorParam
     * @returns Observable<PaginatorEntity<TurmaEntity[]>>
     */
    getAllTurmaPaginated(params: PaginatorParam): Observable<PaginatorEntity<TurmaEntity[]>> {
        return this._http.get<TurmaModel[]>(`${Endpoints.turma}`, { params: HttpUtils.buildParams(params.mapTo()) })
        .pipe(
            map((res) => this._mapperPaginator.mapPaging(
                res['data']?.map(turma => this._mapper.mapTo(turma)), 
                res['meta']
                )
            )
        );
    }

    getTurmaByIdPublic(idPublic: string): Observable<TurmaEntity> {
        return this._http.get<TurmaEntity>(`${Endpoints.turma}/${idPublic}`).pipe(
            map((res) => this._mapper.mapTo(res['data']))
        );
    }

    updateStatusTurmaByIdPublic(idPublic: string, status: boolean): Observable<SuccessVoid> {
        return this._http.put<SuccessVoid>(`${Endpoints.turma}/${idPublic}/status`, { status: status }).pipe(
            map(() => new SuccessVoid())
        );
    }

    updateTurmaByIdPublic(param: TurmaEntity, idPublic: string): Observable<SuccessVoid> {
        return this._http.put<SuccessVoid>(`${Endpoints.turma}/${idPublic}`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }

    saveTurma(param: TurmaEntity): Observable<SuccessVoid> {
        return this._http.post<SuccessVoid>(`${Endpoints.turma}`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }

    saveTurmaInscricao(param: InscricaoEntity, idPublic: string): Observable<SuccessVoid> {
        const dataSubmit = HttpUtils.jsonToFormData(this._mapperInscricao.mapFrom(param));

        return this._http.post<SuccessVoid>(`${Endpoints.turma}/sync-usuario-turma/${idPublic}`, dataSubmit).pipe(
            map(() => new SuccessVoid())
        );
    }
}