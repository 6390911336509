import { Injectable } from '@angular/core';

import { EstadoModel } from './estado.model';
import { Mapper } from "@data/contracts/mapper.contract";
import { EstadoEntity } from '@domain/entities/estado/estado.entity';

@Injectable()
export class EstadoMapper implements Mapper<EstadoModel, EstadoEntity> {
    mapFrom(param: EstadoEntity): EstadoModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            nome: param?.nome,
            sigla: param?.sigla,
        };
    }

    mapTo(param: EstadoModel): EstadoEntity {        
        return {
            id: param?.id,
            idPublic: param?.id_public,
            nome: param?.nome,
            sigla: param?.sigla,
        };
    }
}