import { Observable } from 'rxjs';

import { DocumentacaoEntity } from '@domain/entities/documentacao/documentacao.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { SuccessVoid } from '@infra/success/success';
import { PaginatorParam } from '@shared/utils/paginator';
import { TipoDocumentoEnum } from '@shared/enums/auxiliar/tipo-documento.enum';

export abstract class DocumentacaoRemoteDataSource {
    abstract getAllDocumentacaoStatusAtivo(): Observable<DocumentacaoEntity[]>;
    abstract getAllDocumentacaoStatusAtivoAndTipo(tipo: TipoDocumentoEnum): Observable<DocumentacaoEntity[]>;
    abstract getAllDocumentacaoPaginated(params: PaginatorParam): Observable<PaginatorEntity<DocumentacaoEntity[]>>;
    abstract getDocumentacaoByIdPublic(idPublic: string): Observable<DocumentacaoEntity>;
    abstract updateStatusDocumentacaoByIdPublic(idPublic: string, status: boolean): Observable<SuccessVoid>;
    abstract updateDocumentacaoByIdPublic(param: DocumentacaoEntity, idPublic: string): Observable<SuccessVoid>;
    abstract saveDocumentacao(param: DocumentacaoEntity): Observable<SuccessVoid>;
}