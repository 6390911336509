import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { Failure } from '@infra/contracts/failure.contract';
import { AuxiliarRepository } from "@domain/repositories/auxiliar/auxiliar.repository";
import { AuxiliarEntity } from "@domain/entities/auxiliar/auxiliar.entity";
import { KeyAuxiliarEnum } from "@presentation/shared/enums/key-auxiliar.enum";
import { AuxiliarRemoteDataSource } from "@data/data-source/auxiliar/auxiliar-remote.datasource";

@Injectable()
export class AuxiliarRepositoryImpl implements AuxiliarRepository {

    constructor(
        private readonly _remoteDataSource: AuxiliarRemoteDataSource,
    ) { }

    getAllAuxiliarByChave(chave: KeyAuxiliarEnum): Observable<Either<Failure, AuxiliarEntity[]>> {
        return this._remoteDataSource.getAllAuxiliarByChave(chave).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }
}