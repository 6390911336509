import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './application/presentation/app/app.module';
import { environment } from './environments/environment';

if ( environment.production )
{
    enableProdMode();
}

// https://pt.stackoverflow.com/questions/57972/como-identificar-que-o-usu%C3%A1rio-est%C3%A1-na-pagina-do-meu-site-com-jquery
class CheckVisibility {
    // private hidden: string = 'hidden';
    private document: any = document;

    constructor() {
        this.visibility();
    }

    visibility() {

    }

    // visibility() {
    //     let hidden = 'hidden';

    //     // Standards:
    //     if (hidden in this.document)
    //         this.document.addEventListener("visibilitychange", this.onchange);
    //     else if ((hidden = "mozHidden") in this.document)
    //         this.document.addEventListener("mozvisibilitychange", this.onchange);
    //     else if ((hidden = "webkitHidden") in this.document)
    //         this.document.addEventListener("webkitvisibilitychange", this.onchange);
    //     else if ((hidden = "msHidden") in this.document)
    //         this.document.addEventListener("msvisibilitychange", this.onchange);
    //     // IE 9 and lower:
    //     else if ("onfocusin" in this.document)
    //         this.document.onfocusin = this.document.onfocusout = this.onchange;
    //     // All others:
    //     else
    //         window.onpageshow = window.onpagehide
    //         = window.onfocus = window.onblur = this.onchange;

    //     // set the initial state (but only if browser supports the Page Visibility API)
    //     if( this.document[hidden] !== undefined )
    //         this.onchange({type: this.document[hidden] ? "blur" : "focus"});
    //     else {
    //         alert('Seu navegador não tem suporte a nossa aplicação, recomendamos a utilização do Google Chrome ou Firefox');
    //     }
    // }

    // onchange(evt) {
    //     var v = "visible", h = "hidden",
    //             evtMap = {
    //             focus:v, focusin:v, pageshow:v, blur:h, focusout:h, pagehide:h
    //             };

    //         evt = evt || window.event;
    //         if (evt.type in evtMap)
    //         console.log(evtMap[evt.type]);//Troque aqui pelo ajax
    //         else
    //         console.log(this[hidden] ? "hidden" : "visible");//Troque aqui pelo ajax
    // }
}


function bootstrap() {
    let checkVisibility = new CheckVisibility();
    checkVisibility.visibility();
    
    // document.addEventListener('visibilitychange', () => {
    //     if (!document.hidden) {
    //         console.log('OLÁ');
    //         platformBrowserDynamic().bootstrapModule(AppModule)
    //                         .catch(err => console.error(err));
    //     }
    // });

    platformBrowserDynamic().bootstrapModule(AppModule)
                        .catch(err => console.error(err));
}


bootstrap();