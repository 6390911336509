import { Observable } from 'rxjs';
import { Either } from 'fp-ts/Either';

import { SuccessVoid } from '@infra/success/success';
import { Failure } from '@infra/contracts/failure.contract';
import { CursoEntity } from '@domain/entities/curso/curso.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorParam } from '@shared/utils/paginator';

export abstract class CursoRepository {
    abstract getAllCursoStatusAtivo(): Observable<Either<Failure, CursoEntity[]>>;
    abstract getAllCursoPaginated(params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<CursoEntity[]>>>;
    abstract getCursoByIdPublic(idPublic: string): Observable<Either<Failure, CursoEntity>>;
    abstract updateStatusCursoByIdPublic(idPublic: string, status: boolean): Observable<Either<Failure, SuccessVoid>>;
    abstract updateCursoByIdPublic(param: CursoEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>>;
    abstract saveCurso(param: CursoEntity): Observable<Either<Failure, SuccessVoid>>;

    abstract getCursoTermoUsoEad(): Observable<Either<Failure, Blob>>;
    abstract getAllCursoStatusAtivoByIdPublicCategoria(idPublic: string): Observable<Either<Failure, CursoEntity[]>>;
}