import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { InscricaoModel } from './inscricao.model';
import { AuxiliarMapper } from '../auxiliar/auxiliar.mapper';
import { TurmaMapper } from '../turma/turma.mapper';
import { UserMapper } from '../user/user.mapper';
import { Mapper } from "@data/contracts/mapper.contract";
import { InscricaoEntity } from '@domain/entities/inscricao/inscricao.entity';

@Injectable()
export class InscricaoMapper implements Mapper<InscricaoModel, InscricaoEntity> {

    constructor(
        private readonly _datePipe: DatePipe,
        private readonly _mapperUser: UserMapper,
        private readonly _mapperTurma: TurmaMapper,
        private readonly _mapperAuxiliar: AuxiliarMapper,
    ) { }

    mapFrom(param: InscricaoEntity): InscricaoModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            documento_vinculo: param?.documentoVinculo,
            motivo_inscricao: this._mapperAuxiliar.mapFrom(param?.motivoInscricao),
            user: this._mapperUser.mapFrom(param?.user),
            turma: this._mapperTurma.mapFrom(param?.turma),
            fila_espera: param?.filaEspera,
            deferido: param?.deferido,
            ciencia_termo: param?.cienciaTermo,
            leitura_termo: param?.leituraTermo,
            termo_usoead: param?.termoUsoead,
            created_at: this._datePipe.transform(param?.createdAt, 'yyyy-MM-dd hh:mm:ss'),
        };
    }

    mapTo(param: InscricaoModel): InscricaoEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            documentoVinculo: param?.documento_vinculo,
            motivoInscricao: this._mapperAuxiliar.mapTo(param?.motivo_inscricao),
            user: this._mapperUser.mapTo(param?.user),
            turma: this._mapperTurma.mapTo(param?.turma),
            filaEspera: param?.fila_espera,
            deferido: param?.deferido,
            cienciaTermo: param?.ciencia_termo,
            leituraTermo: param?.leitura_termo,
            termoUsoead: param?.termo_usoead,
            createdAt: this._datePipe.transform(param?.created_at, 'yyyy-MM-dd hh:mm:ss'),
        };
    }
}