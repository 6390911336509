import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CursoRemoteDataSource } from './curso-remote.datasource';
import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { SuccessVoid } from '@infra/success/success';
import { CursoMapper } from '@data/models/curso/curso.mapper';
import { CursoEntity } from '@domain/entities/curso/curso.entity';
import { CursoModel } from '@data/models/curso/curso.model';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorMapper } from '@data/models/paginator/paginator.mapper';
import { PaginatorParam } from "@shared/utils/paginator";
import { HttpUtils } from '@shared/utils/http-utils';

@Injectable()
export class CursoRemoteDataSourceImpl implements CursoRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: CursoMapper,
        private readonly _mapperPaginator: PaginatorMapper<CursoEntity[]>,
    ) { }

    getAllCursoStatusAtivo(): Observable<CursoEntity[]> {
        return this._http.get<CursoModel[]>(`${Endpoints.curso}/ativo`).pipe(
            map(res => res['data']?.map(curso => this._mapper.mapTo(curso)))
        );
    }
    
    /**
     * Busca todas as cursos.
     * 
     * @param page number
     * @param size number
     * @param search string | null
     * @returns Observable<PaginatorEntity<CursoEntity[]>>
     */
    getAllCursoPaginated(params: PaginatorParam): Observable<PaginatorEntity<CursoEntity[]>> {
        return this._http.get<CursoModel[]>(`${Endpoints.curso}`, { params: HttpUtils.buildParams(params.mapTo()) })
        .pipe(
            map((res) => this._mapperPaginator.mapPaging(
                res['data']?.map(curso => this._mapper.mapTo(curso)), 
                res['meta']
                )
            )
        );
    }

    getCursoByIdPublic(idPublic: string): Observable<CursoEntity> {
        return this._http.get<CursoEntity>(`${Endpoints.curso}/${idPublic}`).pipe(
            map((res) => this._mapper.mapTo(res['data']))
        );
    }

    updateStatusCursoByIdPublic(idPublic: string, status: boolean): Observable<SuccessVoid> {
        return this._http.put<SuccessVoid>(`${Endpoints.curso}/${idPublic}/status`, { status: status }).pipe(
            map(() => new SuccessVoid())
        );
    }

    updateCursoByIdPublic(param: CursoEntity, idPublic: string): Observable<SuccessVoid> {
        return this._http.put<SuccessVoid>(`${Endpoints.curso}/${idPublic}`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }

    saveCurso(param: CursoEntity): Observable<SuccessVoid> {
        return this._http.post<SuccessVoid>(`${Endpoints.curso}`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }

    getCursoTermoUsoEad(): Observable<Blob> {
        return this._http.get(`${Endpoints.curso}/termo-uso-ead`, { 
            headers: new HttpHeaders({
                'Content-Type': 'application/pdf',
            }), 
            responseType: 'blob' 
        }).pipe(
            map((res) => res)
        );
    }

    getAllCursoStatusAtivoByIdPublicCategoria(idPublic: string): Observable<CursoEntity[]> {
        return this._http.get<CursoEntity[]>(`${Endpoints.curso}/curso-category/${idPublic}`).pipe(
            map(res => res['data']?.map(curso => this._mapper.mapTo(curso)))
        );
    }
}