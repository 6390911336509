import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DocumentacaoRemoteDataSource } from './documentacao-remote.datasource';
import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { SuccessVoid } from '@infra/success/success';
import { DocumentacaoMapper } from '@data/models/documentacao/documentacao.mapper';
import { DocumentacaoEntity } from '@domain/entities/documentacao/documentacao.entity';
import { DocumentacaoModel } from '@data/models/documentacao/documentacao.model';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorMapper } from '@data/models/paginator/paginator.mapper';
import { PaginatorParam } from "@shared/utils/paginator";
import { HttpUtils } from '@shared/utils/http-utils';
import { TipoDocumentoEnum } from '@shared/enums/auxiliar/tipo-documento.enum';

@Injectable()
export class DocumentacaoRemoteDataSourceImpl implements DocumentacaoRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: DocumentacaoMapper,
        private readonly _mapperPaginator: PaginatorMapper<DocumentacaoEntity[]>,
    ) { }

    getAllDocumentacaoStatusAtivo(): Observable<DocumentacaoEntity[]> {
        return this._http.get<DocumentacaoModel[]>(`${Endpoints.documentacao}/ativo`).pipe(
            map(res => res['data']?.map(documentacao => this._mapper.mapTo(documentacao)))
        );
    }

    getAllDocumentacaoStatusAtivoAndTipo(tipo: TipoDocumentoEnum): Observable<DocumentacaoEntity[]> {
        return this._http.get<DocumentacaoModel[]>(`${Endpoints.documentacao}/ativo/${tipo.toLocaleLowerCase()}`).pipe(
            map(res => res['data']?.map(documentacao => this._mapper.mapTo(documentacao)))
        );
    }
    
    /**
     * @param page number
     * @param size number
     * @param search string | null
     * @returns Observable<PaginatorEntity<DocumentacaoEntity[]>>
     */
    getAllDocumentacaoPaginated(params: PaginatorParam): Observable<PaginatorEntity<DocumentacaoEntity[]>> {
        return this._http.get<DocumentacaoModel[]>(`${Endpoints.documentacao}`, { params: HttpUtils.buildParams(params.mapTo()) })
        .pipe(
            map((res) => this._mapperPaginator.mapPaging(
                res['data']?.map(documentacao => this._mapper.mapTo(documentacao)), 
                res['meta']
                )
            )
        );
    }

    getDocumentacaoByIdPublic(idPublic: string): Observable<DocumentacaoEntity> {
        return this._http.get<DocumentacaoEntity>(`${Endpoints.documentacao}/${idPublic}`).pipe(
            map((res) => this._mapper.mapTo(res['data']))
        );
    }

    updateStatusDocumentacaoByIdPublic(idPublic: string, status: boolean): Observable<SuccessVoid> {
        return this._http.put<SuccessVoid>(`${Endpoints.documentacao}/${idPublic}/status`, { status: status }).pipe(
            map(() => new SuccessVoid())
        );
    }

    updateDocumentacaoByIdPublic(param: DocumentacaoEntity, idPublic: string): Observable<SuccessVoid> {
        return this._http.put<SuccessVoid>(`${Endpoints.documentacao}/${idPublic}`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }

    saveDocumentacao(param: DocumentacaoEntity): Observable<SuccessVoid> {
        return this._http.post<SuccessVoid>(`${Endpoints.documentacao}`, this._mapper.mapFrom(param)).pipe(
            map(() => new SuccessVoid())
        );
    }
}