import { Injectable } from '@angular/core';

import { ProfessorModel } from './professor.model';
import { PessoaMapper } from '../pessoa/pessoa.mapper';
import { AreaInteresseMapper } from '../area-interesse/area-interesse.mapper';
import { FormacaoProfissionalMapper } from '../formacao-profissional/formacao-profissional.mapper';
import { ExperienciaProfissionalMapper } from '../experiencia-profissional/experiencia-profissional.mapper';
import { ArquivoUsuarioMapper } from '../arquivo-usuario/arquivo-usuario.mapper';
import { Mapper } from "@data/contracts/mapper.contract";
import { ProfessorEntity } from '@domain/entities/professor/professor.entity';
import { applyMaskNumeric } from '@shared/utils/utils';

@Injectable()
export class ProfessorMapper implements Mapper<ProfessorModel, ProfessorEntity> {

    constructor(
        private readonly _mapperPessoa: PessoaMapper,
        private readonly _mapperAreaInteresse: AreaInteresseMapper,
        private readonly _mapperFormacaoProfissional: FormacaoProfissionalMapper,
        private readonly _mapperExperienciaProfissional: ExperienciaProfissionalMapper,
        private readonly _mapperArquivoUsuario: ArquivoUsuarioMapper,
    ) { }

    mapFrom(param: ProfessorEntity): ProfessorModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            pessoa: this._mapperPessoa.mapFrom(param?.pessoa),
            inss: param?.inss?.toString()?.replace(/\D/g, ''),
            pis_pasep: param?.pisPasep?.toString()?.replace(/\D/g, ''),
            pontuacao: param?.pontuacao,
            status_deferimento: param?.statusDeferimento,
            status: param?.status,

            area_interesse: param?.areaInteresse?.map(areaInteresse => this._mapperAreaInteresse.mapFrom(areaInteresse)),
            formacao: param?.formacao?.map(formacao => this._mapperFormacaoProfissional.mapFrom(formacao)),
            experiencia: param?.experiencia?.map(experiencia => this._mapperExperienciaProfissional.mapFrom(experiencia)),
            documentacao: param?.documentacao?.map(documentacao => this._mapperArquivoUsuario.mapFrom(documentacao)),
        };
    }

    mapTo(param: ProfessorModel): ProfessorEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            pessoa: this._mapperPessoa.mapTo(param?.pessoa),
            inss: param?.inss?.toString()?.replace(/\D/g, ''),
            pisPasep: applyMaskNumeric(param?.pis_pasep?.toString()?.replace(/\D/g, ''), '000.00000.00-0'),
            pontuacao: param?.pontuacao,
            statusDeferimento: param?.status_deferimento,
            status: param?.status,

            areaInteresse: param?.area_interesse?.map(areaInteresse => this._mapperAreaInteresse.mapTo(areaInteresse)),
            formacao: param?.formacao?.map(formacao => this._mapperFormacaoProfissional.mapTo(formacao)),
            experiencia: param?.experiencia?.map(experiencia => this._mapperExperienciaProfissional.mapTo(experiencia)),
            documentacao: param?.documentacao?.map(documentacao => this._mapperArquivoUsuario.mapTo(documentacao)),
        };
    }
}