import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { MunicipioRemoteDataSource } from './municipio-remote.datasource';
import { MunicipioMapper } from '@data/models/municipio/municipio.mapper';
import { MunicipioEntity } from '@domain/entities/municipio/municipio.entity';
import { MunicipioModel } from '@data/models/municipio/municipio.model';
import { EstadoEntity } from '@domain/entities/estado/estado.entity';

@Injectable()
export class MunicipioRemoteDataSourceImpl implements MunicipioRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: MunicipioMapper
    ) { }

    getAllMunicipiosByEstado(estado: EstadoEntity): Observable<MunicipioEntity[]> {
        return this._http.get<MunicipioModel[]>(`${Endpoints.address}/municipios/${estado.id}`).pipe(
            map(res => res['data']?.map(municipio => this._mapper.mapTo(municipio)))
        );
    }
}