import { Observable } from 'rxjs';
import { Either } from 'fp-ts/Either';

import { Failure } from '@infra/contracts/failure.contract';
import { UserEntity } from '@domain/entities/user/user.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { SuccessVoid } from '@infra/success/success';
import { PaginatorParam } from '@shared/utils/paginator';

export abstract class UserRepository {
    abstract getUserAuth(): Observable<Either<Failure, UserEntity>>;
    abstract getAllUserStatusAtivo(): Observable<Either<Failure, UserEntity[]>>;
    abstract getAllUserPaginated(params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<UserEntity[]>>>;
    abstract getUserByIdPublic(idPublic: string): Observable<Either<Failure, UserEntity>>;
    abstract updateStatusUserByIdPublic(idPublic: string, status: boolean): Observable<Either<Failure, SuccessVoid>>;
    abstract updateUserByIdPublic(param: UserEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>>;
    abstract saveUser(param: UserEntity): Observable<Either<Failure, SuccessVoid>>;
    abstract saveUserExterno(param: UserEntity): Observable<Either<Failure, SuccessVoid>>;

    abstract updateUserInternoByIdPublic(param: UserEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>>;
}