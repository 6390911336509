import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { DocumentacaoRemoteDataSource } from "@data/data-source/documentacao/documentacao-remote.datasource";
import { DocumentacaoRepository } from "@domain/repositories/documentacao/documentacao.repository";
import { DocumentacaoEntity } from "@domain/entities/documentacao/documentacao.entity";
import { PaginatorEntity } from "@domain/entities/paginator/paginator.entity";
import { Failure } from '@infra/contracts/failure.contract';
import { SuccessVoid } from "@infra/success/success";
import { PaginatorParam } from "@shared/utils/paginator";
import { TipoDocumentoEnum } from '@shared/enums/auxiliar/tipo-documento.enum';

@Injectable()
export class DocumentacaoRepositoryImpl implements DocumentacaoRepository {

    constructor(
        private readonly _remoteDataSource: DocumentacaoRemoteDataSource,
    ) { }

    getAllDocumentacaoPaginated(params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<DocumentacaoEntity[]>>> {
        return this._remoteDataSource.getAllDocumentacaoPaginated(params).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getAllDocumentacaoStatusAtivo(): Observable<Either<Failure, DocumentacaoEntity[]>> {
        return this._remoteDataSource.getAllDocumentacaoStatusAtivo().pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getAllDocumentacaoStatusAtivoAndTipo(tipo: TipoDocumentoEnum): Observable<Either<Failure, DocumentacaoEntity[]>> {
        return this._remoteDataSource.getAllDocumentacaoStatusAtivoAndTipo(tipo).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getDocumentacaoByIdPublic(idPublic: string): Observable<Either<Failure, DocumentacaoEntity>> {
        return this._remoteDataSource.getDocumentacaoByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    updateStatusDocumentacaoByIdPublic(idPublic: string, status: boolean): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateStatusDocumentacaoByIdPublic(idPublic, status).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    updateDocumentacaoByIdPublic(param: DocumentacaoEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateDocumentacaoByIdPublic(param, idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    saveDocumentacao(param: DocumentacaoEntity): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.saveDocumentacao(param).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }
}