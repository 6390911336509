import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginAuthUseCase } from './usecases/auth-usecases/login-auth.usecase';
import { LogoutAuthUseCase } from './usecases/auth-usecases/logout-auth.usecase';
import { ForgotPasswordUseCase } from './usecases/auth-usecases/forgot-password.usecase';
import { ResetPasswordUseCase } from './usecases/auth-usecases/reset-password.usecase';
import { ChangePasswordUseCase } from './usecases/auth-usecases/change-password.usecase';

import { GetAllAuxiliarByChaveUseCase } from './usecases/auxiliar-usecases/get-all-auxiliar-by-chave.usecase';
import { GetAllEstadosUseCase } from './usecases/estado-usecases/get-all-estados.usecase';
import { GetAllMunicipiosByEstadoUseCase } from './usecases/municipio-usecases/get-all-municipios-by-estado.usecase';
import { SaveUserExternoUseCase } from './usecases/user-usecases/save-user-externo.usecase';

import { GetUserAuthUseCase } from './usecases/user-usecases/get-user-auth.usecase';
import { GetAllUserStatusAtivoUseCase } from './usecases/user-usecases/get-all-user-status-ativo.usecase';
import { GetAllUserPaginatedUseCase } from './usecases/user-usecases/get-all-user-paginated.usecase';
import { GetUserByIdPublicUseCase } from './usecases/user-usecases/get-user-by-idpublic.usecase';
import { UpdateStatusUserByIdPublicUseCase } from './usecases/user-usecases/update-status-user-by-idpublic.usecase';
import { UpdateUserByIdPublicUseCase } from './usecases/user-usecases/update-user-by-idpublic.usecase';
import { UpdateUserInternoByIdPublicUseCase } from './usecases/user-usecases/update-user-interno-by-idpublic.usecase';
import { SaveUserUseCase } from './usecases/user-usecases/save-user.usecase';

import { GetAllOrgaoStatusAtivoUseCase } from './usecases/orgao-usecases/get-all-orgao-status-ativo.usecase';
import { GetAllOrgaoPaginatedUseCase } from './usecases/orgao-usecases/get-all-orgao-paginated.usecase';
import { GetOrgaoByIdPublicUseCase } from './usecases/orgao-usecases/get-orgao-by-idpublic.usecase';
import { UpdateStatusOrgaoByIdPublicUseCase } from './usecases/orgao-usecases/update-status-orgao-by-idpublic.usecase';
import { UpdateOrgaoByIdPublicUseCase } from './usecases/orgao-usecases/update-orgao-by-idpublic.usecase';
import { SaveOrgaoUseCase } from './usecases/orgao-usecases/save-orgao.usecase';

import { GetAllCategoriaStatusAtivoUseCase } from './usecases/categoria-usecases/get-all-categoria-status-ativo.usecase';
import { GetAllCategoriaPaginatedUseCase } from './usecases/categoria-usecases/get-all-categoria-paginated.usecase';
import { GetCategoriaByIdPublicUseCase } from './usecases/categoria-usecases/get-categoria-by-idpublic.usecase';
import { UpdateStatusCategoriaByIdPublicUseCase } from './usecases/categoria-usecases/update-status-categoria-by-idpublic.usecase';
import { UpdateCategoriaByIdPublicUseCase } from './usecases/categoria-usecases/update-categoria-by-idpublic.usecase';
import { SaveCategoriaUseCase } from './usecases/categoria-usecases/save-categoria.usecase';

import { GetAllDocumentacaoStatusAtivoUseCase } from './usecases/documentacao-usecases/get-all-documentacao-status-ativo.usecase';
import { GetAllDocumentacaoStatusAtivoAndTipoUseCase } from './usecases/documentacao-usecases/get-all-documentacao-status-ativo-and-tipo.usecase';
import { GetAllDocumentacaoPaginatedUseCase } from './usecases/documentacao-usecases/get-all-documentacao-paginated.usecase';
import { GetDocumentacaoByIdPublicUseCase } from './usecases/documentacao-usecases/get-documentacao-by-idpublic.usecase';
import { UpdateStatusDocumentacaoByIdPublicUseCase } from './usecases/documentacao-usecases/update-status-documentacao-by-idpublic.usecase';
import { UpdateDocumentacaoByIdPublicUseCase } from './usecases/documentacao-usecases/update-documentacao-by-idpublic.usecase';
import { SaveDocumentacaoUseCase } from './usecases/documentacao-usecases/save-documentacao.usecase';

import { GetDocumentoByIdPublicUseCase } from './usecases/arquivo-usuario/get-documento-by-id-public.usecase';

import { GetAllCursoStatusAtivoUseCase } from './usecases/curso-usecases/get-all-curso-status-ativo.usecase';
import { GetAllCursoPaginatedUseCase } from './usecases/curso-usecases/get-all-curso-paginated.usecase';
import { GetCursoByIdPublicUseCase } from './usecases/curso-usecases/get-curso-by-idpublic.usecase';
import { UpdateStatusCursoByIdPublicUseCase } from './usecases/curso-usecases/update-status-curso-by-idpublic.usecase';
import { UpdateCursoByIdPublicUseCase } from './usecases/curso-usecases/update-curso-by-idpublic.usecase';
import { SaveCursoUseCase } from './usecases/curso-usecases/save-curso.usecase';
import { GetCursoTermoUsoEadUseCase } from './usecases/curso-usecases/get-curso-termo-uso-ead.usercase';
import { GetAllCursoStatusAtivoByIdPublicCategoriaUseCase } from './usecases/curso-usecases/get-all-curso-status-ativo-by-idpublic-categoria.usecase';

import { GetAllCursoMoodleStatusAtivoUseCase } from './usecases/curso-moodle-usecases/get-all-curso-moodle-status-ativo.usecase';
import { GetAllCursoMoodlePaginatedUseCase } from './usecases/curso-moodle-usecases/get-all-curso-moodle-paginated.usecase';
import { GetCursoMoodleByIdPublicUseCase } from './usecases/curso-moodle-usecases/get-curso-moodle-by-idpublic.usecase';
import { SyncCourseMoodleUseCase } from './usecases/curso-moodle-usecases/sync-course-moodle.usecase';

import { GetAllTurmaStatusAtivoUseCase } from './usecases/turma-usecases/get-all-turma-status-ativo.usecase';
import { GetAllTurmaPaginatedUseCase } from './usecases/turma-usecases/get-all-turma-paginated.usecase';
import { GetTurmaByIdPublicUseCase } from './usecases/turma-usecases/get-turma-by-idpublic.usecase';
import { UpdateStatusTurmaByIdPublicUseCase } from './usecases/turma-usecases/update-status-turma-by-idpublic.usecase';
import { UpdateTurmaByIdPublicUseCase } from './usecases/turma-usecases/update-turma-by-idpublic.usecase';
import { SaveTurmaUseCase } from './usecases/turma-usecases/save-turma.usecase';
import { SaveTurmaInscricaoUseCase } from './usecases/turma-usecases/save-turma-inscricao.usecase';

import { GetAllInscricaoByTurmaPaginatedUseCase } from './usecases/inscricao-usecases/get-all-inscricao-by-turma-paginated.usecase';
import { GetAllMyInscricaoPaginatedUseCase } from './usecases/inscricao-usecases/get-all-my-inscricao-paginated.usecase';
import { GetMyInscricaoByIdPublicUseCase } from './usecases/inscricao-usecases/get-my-inscricao-by-idpublic.usecase';
import { DeleteInscricaoByIdPublicUseCase } from './usecases/inscricao-usecases/delete-inscricao-by-idpublic.usecase';
import { RedefinirInscricaoByIdPublicUseCase } from './usecases/inscricao-usecases/redefinir-inscricao-by-idpublic.usecase';
import { NotificacaoInscricaoByIdPublicUseCase } from './usecases/inscricao-usecases/notificacao-inscricao-by-idpublic.usecase';
import { DeferimentoInscricaoByIdPublicUseCase } from './usecases/inscricao-usecases/deferimento-inscricao-by-idpublic.usecase';
import { GetMyInscricaoWithTermoByIdPublicUseCase } from './usecases/inscricao-usecases/get-my-inscricao-with-termo-by-idpublic.usecase';
import { UpdateTermoMyInscricaoByIdPublicUseCase } from './usecases/inscricao-usecases/update-termo-my-inscricao-by-idpublic.usecase';
import { GetDocumentoVinculoInscricaoByIdPublicUseCase } from './usecases/inscricao-usecases/get-documento-vinculo-inscricao-by-id-public.usecase';

import { GetAllProfessorStatusAtivoUseCase } from './usecases/professor-usecases/get-all-professor-status-ativo.usecase';
import { GetMeuCredenciamentoProfessorUseCase } from './usecases/professor-usecases/get-meu-credenciamento-professor.usecase';
import { GetAllProfessorPaginatedUseCase } from './usecases/professor-usecases/get-all-professor-paginated.usecase';
import { GetProfessorByIdPublicUseCase } from './usecases/professor-usecases/get-professor-by-idpublic.usecase';
import { UpdateStatusProfessorByIdPublicUseCase } from './usecases/professor-usecases/update-status-professor-by-idpublic.usecase';
import { UpdateProfessorByIdPublicUseCase } from './usecases/professor-usecases/update-professor-by-idpublic.usecase';
import { UpdateProfessorCredenciamentoUseCase } from './usecases/professor-usecases/update-professor-credenciamento.usecase';
import { SaveProfessorUseCase } from './usecases/professor-usecases/save-professor.usecase';
import { DeleteProfessorByIdPublicUseCase } from './usecases/professor-usecases/delete-professor-by-idpublic.usecase';

import { GetAllPerfilStatusAtivoUseCase } from './usecases/perfil-usecases/get-all-perfil-status-ativo.usecase';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    LoginAuthUseCase,
    LogoutAuthUseCase,
    ForgotPasswordUseCase,
    ResetPasswordUseCase,
    ChangePasswordUseCase,

    //User
    GetUserAuthUseCase,    
    GetAllUserStatusAtivoUseCase,
    GetAllUserPaginatedUseCase,
    GetUserByIdPublicUseCase,
    UpdateStatusUserByIdPublicUseCase,
    UpdateUserByIdPublicUseCase,
    UpdateUserInternoByIdPublicUseCase,
    SaveUserUseCase,

    //Auxiliar
    GetAllAuxiliarByChaveUseCase,

    //Órgãos
    GetAllOrgaoStatusAtivoUseCase,
    GetAllOrgaoPaginatedUseCase,
    GetOrgaoByIdPublicUseCase,
    UpdateStatusOrgaoByIdPublicUseCase,
    UpdateOrgaoByIdPublicUseCase,
    SaveOrgaoUseCase,

    //Categorias
    GetAllCategoriaStatusAtivoUseCase,
    GetAllCategoriaPaginatedUseCase,
    GetCategoriaByIdPublicUseCase,
    UpdateStatusCategoriaByIdPublicUseCase,
    UpdateCategoriaByIdPublicUseCase,
    SaveCategoriaUseCase,

    // Documentação
    GetAllDocumentacaoStatusAtivoUseCase,
    GetAllDocumentacaoStatusAtivoAndTipoUseCase,
    GetAllDocumentacaoPaginatedUseCase,
    GetDocumentacaoByIdPublicUseCase,
    UpdateStatusDocumentacaoByIdPublicUseCase,
    UpdateDocumentacaoByIdPublicUseCase,
    SaveDocumentacaoUseCase,

    // Arquivo Usuário
    GetDocumentoByIdPublicUseCase,

    // Curso
    GetAllCursoStatusAtivoUseCase,
    GetAllCursoPaginatedUseCase,
    GetCursoByIdPublicUseCase,
    UpdateStatusCursoByIdPublicUseCase,
    UpdateCursoByIdPublicUseCase,
    SaveCursoUseCase,
    GetCursoTermoUsoEadUseCase,
    GetAllCursoStatusAtivoByIdPublicCategoriaUseCase,

    // Curso Moodle
    GetAllCursoMoodleStatusAtivoUseCase,
    GetAllCursoMoodlePaginatedUseCase,
    GetCursoMoodleByIdPublicUseCase,
    SyncCourseMoodleUseCase,

    // Turma
    GetAllTurmaStatusAtivoUseCase,
    GetAllTurmaPaginatedUseCase,
    GetTurmaByIdPublicUseCase,
    UpdateStatusTurmaByIdPublicUseCase,
    UpdateTurmaByIdPublicUseCase,
    SaveTurmaUseCase,
    SaveTurmaInscricaoUseCase,

    // Inscricao
    GetAllInscricaoByTurmaPaginatedUseCase,
    GetAllMyInscricaoPaginatedUseCase,
    GetMyInscricaoByIdPublicUseCase,
    DeleteInscricaoByIdPublicUseCase,
    NotificacaoInscricaoByIdPublicUseCase,
    RedefinirInscricaoByIdPublicUseCase,
    DeferimentoInscricaoByIdPublicUseCase,
    GetMyInscricaoWithTermoByIdPublicUseCase,
    UpdateTermoMyInscricaoByIdPublicUseCase,
    GetDocumentoVinculoInscricaoByIdPublicUseCase,

    //Professor
    GetAllProfessorStatusAtivoUseCase,
    GetMeuCredenciamentoProfessorUseCase,
    GetAllProfessorPaginatedUseCase,
    GetProfessorByIdPublicUseCase,
    UpdateStatusProfessorByIdPublicUseCase,
    UpdateProfessorByIdPublicUseCase,
    UpdateProfessorCredenciamentoUseCase,
    SaveProfessorUseCase,
    DeleteProfessorByIdPublicUseCase,

    GetAllEstadosUseCase,
    GetAllMunicipiosByEstadoUseCase,

    SaveUserExternoUseCase,

    //Perfil
    GetAllPerfilStatusAtivoUseCase,
  ]
})
export class DomainModule { }
