import { Injectable } from '@angular/core';

import { AreaInteresseModel } from './area-interesse.model';
import { CursoMapper } from '../curso/curso.mapper';
import { Mapper } from "@data/contracts/mapper.contract";
import { AreaInteresseEntity } from '@domain/entities/area-interesse/area-interesse.entity';

@Injectable()
export class AreaInteresseMapper implements Mapper<AreaInteresseModel, AreaInteresseEntity> {

    constructor(
        private readonly _mapperCurso: CursoMapper,
    ) { }

    mapFrom(param: AreaInteresseEntity): AreaInteresseModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            categoria: param?.categoria,
            curso: param?.curso?.map(curso => this._mapperCurso.mapFrom(curso)),
        };
    }

    mapTo(param: AreaInteresseModel): AreaInteresseEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            categoria: param?.categoria,
            curso: param?.curso?.map(curso => this._mapperCurso.mapTo(curso)),
        };
    }
}