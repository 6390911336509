import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { TurmaModel } from './turma.model';
import { AuxiliarMapper } from '../auxiliar/auxiliar.mapper';
import { CursoMapper } from '../curso/curso.mapper';
import { ProfessorMapper } from '../professor/professor.mapper';
import { Mapper } from "@data/contracts/mapper.contract";
import { TurmaEntity } from '@domain/entities/turma/turma.entity';
import { transformNumberToBoolean } from '@presentation/shared/utils/utils';

@Injectable()
export class TurmaMapper implements Mapper<TurmaModel, TurmaEntity> {

    constructor(
        private readonly _datePipe: DatePipe,
        private readonly _mapperAuxiliar: AuxiliarMapper,
        private readonly _mapperCurso: CursoMapper,
        private readonly _mapperProfessor: ProfessorMapper,
    ) { }

    mapFrom(param: TurmaEntity): TurmaModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            curso: this._mapperCurso.mapFrom(param?.curso),
            codigo: param?.codigo,
            vagas: param?.vagas,
            inscritos: param?.inscritos,
            indeferidos: param?.indeferidos,
            deferidos: param?.deferidos,
            fechar: param?.fechar,
            publicar: param?.publicar,
            status: param?.status,
            turno: this._mapperAuxiliar.mapFrom(param?.turno),
            data_inicio: param?.dataInicio,
            data_fim: param?.dataFim,
            horario_inicio: param?.horarioInicio,
            horario_fim: param?.horarioFim,
            dias_participacao: param?.diasParticipacao || 30,
            local: param?.local,
            observacao: param?.observacao,
            permitir_fila_espera: param?.permitirFilaEspera,
            instrutor: this._mapperProfessor.mapFrom(param?.instrutor),
        };
    }

    mapTo(param: TurmaModel): TurmaEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            curso: this._mapperCurso.mapTo(param?.curso),
            codigo: param?.codigo,
            vagas: param?.vagas,
            inscritos: param?.inscritos,
            indeferidos: param?.indeferidos,
            deferidos: param?.deferidos,
            fechar: transformNumberToBoolean(param?.fechar),
            publicar: transformNumberToBoolean(param?.publicar),
            status: transformNumberToBoolean(param?.status),
            turno: this._mapperAuxiliar.mapTo(param?.turno),
            dataInicio: this._datePipe.transform(param?.data_inicio, 'yyyy-MM-dd'),
            dataFim: this._datePipe.transform(param?.data_fim, 'yyyy-MM-dd'),
            horarioInicio: this._datePipe.transform(param?.horario_inicio, 'hh:mm'),
            horarioFim: this._datePipe.transform(param?.horario_fim, 'hh:mm'),
            diasParticipacao: param?.dias_participacao || 30,
            local: param?.local,
            observacao: param?.observacao,
            permitirFilaEspera: transformNumberToBoolean(param?.permitir_fila_espera),
            instrutor: this._mapperProfessor.mapTo((param?.instrutor ?? [])[0]),
            inscricao: param?.inscricao ? true : false,
        };
    }
}