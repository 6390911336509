export enum PermissaoEnum {
    //Usado no collapsable
    VISUALIZAR_CONFIGURACAO="VISUALIZAR_CONFIGURACAO",
    VISUALIZAR_AREA_ACADEMICA="VISUALIZAR_AREA_ACADEMICA",

    //Perfil, no momento, sem uso.
    VISUALIZAR_PERFIL="VISUALIZAR_PERFIL",
    LISTAR_PERFIL="LISTAR_PERFIL",
    CADASTRAR_PERFIL="CADASTRAR_PERFIL",
    ATUALIZAR_PERFIL="ATUALIZAR_PERFIL",
    DELETAR_PERFIL="DELETAR_PERFIL",

    //Permissao, no momento, sem uso.
    LISTAR_PERMISSAO="LISTAR_PERMISSAO",
    CADASTRAR_PERMISSAO="CADASTRAR_PERMISSAO",
    ATUALIZAR_PERMISSAO="ATUALIZAR_PERMISSAO",
    DELETAR_PERMISSAO="DELETAR_PERMISSAO",

    //Auxiliar, no momento, sem uso.
    VISUALIZAR_AUXILIAR="VISUALIZAR_AUXILIAR",
    LISTAR_AUXILIAR="LISTAR_AUXILIAR",
    CADASTRAR_AUXILIAR="CADASTRAR_AUXILIAR",
    ATUALIZAR_AUXILIAR="ATUALIZAR_AUXILIAR",
    DELETAR_AUXILIAR="DELETAR_AUXILIAR",

    LISTAR_INSCRITO_TURMA="LISTAR_INSCRITO_TURMA",
    CADASTRAR_INSCRITO_TURMA="CADASTRAR_INSCRITO_TURMA",
    VISUALIZAR_INSCRITO_TURMA="VISUALIZAR_INSCRITO_TURMA",

    //Inscritos
    LISTAR_INSCRITO="LISTAR_INSCRITO",
    CADASTRAR_INSCRITO="CADASTRAR_INSCRITO",
    ATUALIZAR_INSCRITO="ATUALIZAR_INSCRITO",

    //Documentação
    LISTAR_DOCUMENTACAO="LISTAR_DOCUMENTACAO",
    CADASTRAR_DOCUMENTACAO="CADASTRAR_DOCUMENTACAO",
    ATUALIZAR_DOCUMENTACAO="ATUALIZAR_DOCUMENTACAO",

    //Usuário
    VISUALIZAR_USUARIO="VISUALIZAR_USUARIO",
    LISTAR_USUARIO="LISTAR_USUARIO",
    CADASTRAR_USUARIO="CADASTRAR_USUARIO",
    ATUALIZAR_USUARIO="ATUALIZAR_USUARIO",


    //Categoria
    LISTAR_CATEGORIA="LISTAR_CATEGORIA",
    CADASTRAR_CATEGORIA="CADASTRAR_CATEGORIA",
    ATUALIZAR_CATEGORIA="ATUALIZAR_CATEGORIA",

    //Professor
    LISTAR_PROFESSOR="LISTAR_PROFESSOR",
    CADASTRAR_PROFESSOR="CADASTRAR_PROFESSOR",
    ATUALIZAR_PROFESSOR="ATUALIZAR_PROFESSOR",

    //Aluno
    LISTAR_ALUNO="LISTAR_ALUNO",
    CADASTRAR_ALUNO="CADASTRAR_ALUNO",
    ATUALIZAR_ALUNO="ATUALIZAR_ALUNO",

    //Turma
    LISTAR_TURMA="LISTAR_TURMA",
    CADASTRAR_TURMA="CADASTRAR_TURMA",
    ATUALIZAR_TURMA="ATUALIZAR_TURMA",

    // Órgão
    LISTAR_ORGAO="LISTAR_ORGAO",
    CADASTRAR_ORGAO="CADASTRAR_ORGAO",
    ATUALIZAR_ORGAO="ATUALIZAR_ORGAO",
    DELETAR_ORGAO="DELETAR_ORGAO",

    // Acadêmico > Curso
    LISTAR_CURSO="LISTAR_CURSO",
    CADASTRAR_CURSO="CADASTRAR_CURSO",
    ATUALIZAR_CURSO="ATUALIZAR_CURSO",
    DELETAR_CURSO="DELETAR_CURSO",


    // Só para ALUNO / Professor
    VISUALIZAR_CURSO="VISUALIZAR_CURSO",
    VISUALIZAR_MEU_CURSO="VISUALIZAR_MEU_CURSO",
    LISTAR_MEU_CURSO="LISTAR_MEU_CURSO",
    DELETAR_MEU_CURSO="DELETAR_MEU_CURSO",
    LISTAR_CURSO_PRESENCIAL="LISTAR_CURSO_PRESENCIAL",
    LISTAR_CURSO_EAD="LISTAR_CURSO_EAD",
    LISTAR_CURSO_ENCERRADO="LISTAR_CURSO_ENCERRADO",
    CADASTRAR_INSCRICAO_CURSO_PRESENCIAL="CADASTRAR_INSCRICAO_CURSO_PRESENCIAL",
    CADASTRAR_INSCRICAO_CURSO_EAD="CADASTRAR_INSCRICAO_CURSO_EAD",
    CADASTRAR_CREDENCIAMENTO="CADASTRAR_CREDENCIAMENTO",


    // Cursos Moodle
    LISTAR_CURSO_MOODLE="LISTAR_CURSO_MOODLE",
}