import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { UserRepository } from '@domain/repositories/user/user.repository';
import { UserEntity } from '@domain/entities/user/user.entity';

@Injectable()
export class GetAllUserStatusAtivoUseCase implements Usecase<void, UserEntity[]> {

    constructor(
        private readonly _repository: UserRepository
    ) { }

    execute(): Observable<UserEntity[] | Failure> {
        return this._repository.getAllUserStatusAtivo().pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<UserEntity[]>) => res.right)
        );
    }
}