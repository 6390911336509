import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { Failure } from '@infra/contracts/failure.contract';
import { EstadoRemoteDataSource } from "@data/data-source/estado/estado-remote.datasource";
import { EstadoRepository } from "@domain/repositories/estado/estado.repository";
import { EstadoEntity } from "@domain/entities/estado/estado.entity";

@Injectable()
export class EstadoRepositoryImpl implements EstadoRepository {

    constructor(
        private readonly _remoteDataSource: EstadoRemoteDataSource,
    ) { }

    getAllEstados(): Observable<Either<Failure, EstadoEntity[]>> {
        return this._remoteDataSource.getAllEstados().pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }
}