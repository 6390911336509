import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { ProfessorRemoteDataSource } from "@data/data-source/professor/professor-remote.datasource";
import { ProfessorRepository } from "@domain/repositories/professor/professor.repository";
import { ProfessorEntity } from "@domain/entities/professor/professor.entity";
import { PaginatorEntity } from "@domain/entities/paginator/paginator.entity";
import { Failure } from '@infra/contracts/failure.contract';
import { SuccessVoid } from "@infra/success/success";
import { PaginatorParam } from "@shared/utils/paginator";

@Injectable()
export class ProfessorRepositoryImpl implements ProfessorRepository {

    constructor(
        private readonly _remoteDataSource: ProfessorRemoteDataSource,
    ) { }

    getAllProfessorPaginated(params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<ProfessorEntity[]>>> {
        return this._remoteDataSource.getAllProfessorPaginated(params).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getAllProfessorStatusAtivo(): Observable<Either<Failure, ProfessorEntity[]>> {
        return this._remoteDataSource.getAllProfessorStatusAtivo().pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getMeuCredenciamentoProfessor(): Observable<Either<Failure, ProfessorEntity>> {
        return this._remoteDataSource.getMeuCredenciamentoProfessor().pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getProfessorByIdPublic(idPublic: string): Observable<Either<Failure, ProfessorEntity>> {
        return this._remoteDataSource.getProfessorByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    updateStatusProfessorByIdPublic(idPublic: string, status: boolean): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateStatusProfessorByIdPublic(idPublic, status).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    updateProfessorByIdPublic(param: ProfessorEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateProfessorByIdPublic(param, idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    updateProfessorCredenciamento(param: ProfessorEntity): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateProfessorCredenciamento(param).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    saveProfessor(param: ProfessorEntity): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.saveProfessor(param).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    deleteProfessorByIdPublic(idPublic: string): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.deleteProfessorByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }
}