import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ArquivoUsuarioRemoteDataSource } from './arquivo-usuario-remote.datasource';
import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { DocumentacaoMapper } from '@data/models/documentacao/documentacao.mapper';
import { DocumentacaoEntity } from '@domain/entities/documentacao/documentacao.entity';
import { PaginatorMapper } from '@data/models/paginator/paginator.mapper';

@Injectable()
export class ArquivoUsuarioRemoteDataSourceImpl implements ArquivoUsuarioRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: DocumentacaoMapper,
        private readonly _mapperPaginator: PaginatorMapper<DocumentacaoEntity[]>,
    ) { }

    getDocumentoByIdPublic(idPublic: string): Observable<Blob> {
        return this._http.get(`${Endpoints.documentacao}/arquivo/${idPublic}`, { 
            headers: new HttpHeaders({
                'Content-Type': 'application/pdf',
            }), 
            responseType: 'blob' 
        }).pipe(
            map((res) => res)
        );
    }
}