import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import localePt from '@angular/common/locales/pt';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { LightboxModule } from 'ngx-lightbox';
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { NgxSpinnerModule } from "ngx-spinner";

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { fuseConfig } from '@layout/utils/fuse-config';
import { LayoutModule } from '@layout/layout.module';

import { DataModule } from '@data/data.module';
import { DomainModule } from '@domain/domain.module';
import { InfraModule } from '@infra/infra.module';
import { LocalStorage } from '@infra/storage/local-storage';
import { HttpServiceInterceptor } from '@infra/http/http-service.interceptor';
import { SessionAuthed } from '@shared/utils/session-authed';
import { getBrPaginatorIntl } from '@shared/functions/customLabelPaginator';

const maskConfig: Partial<IConfig> = {
    dropSpecialCharacters: false,
};

export const customCurrencyMaskConfig = {
    align: "right",
    allowNegative: false,
    allowZero: true,
    decimal: ",",
    precision: 2,
    prefix: "",
    suffix: "",
    thousands: ".",
    nullable: true,
    inputMode: CurrencyMaskInputMode.FINANCIAL
};

registerLocaleData(localePt, 'pt');

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,

        NgxMaskModule.forRoot(maskConfig),
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),

        // NgxGoogleAnalyticsModule.forRoot('G-53ES0RM5DC'),
        // NgxGoogleAnalyticsRouterModule,

        LightboxModule,

        QuillModule.forRoot({
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                    ['blockquote', 'code-block'],

                    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                    [{ 'direction': 'rtl' }],                         // text direction

                    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                    [{ 'font': [] }],
                    [{ 'align': [] }],

                    ['clean'],                                         // remove formatting button

                ]
            }
        }),
        NgxSpinnerModule,

        DataModule,
        DomainModule,
        InfraModule,
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        LocalStorage,
        SessionAuthed,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpServiceInterceptor,
            multi: true
        },
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
        { provide: MatPaginatorIntl, useValue: getBrPaginatorIntl() }
    ],
})
export class AppModule {
}
