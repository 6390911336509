import { Observable } from 'rxjs';
import { Either } from 'fp-ts/Either';

import { SuccessVoid } from '@infra/success/success';
import { Failure } from '@infra/contracts/failure.contract';
import { DocumentacaoEntity } from '@domain/entities/documentacao/documentacao.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorParam } from '@shared/utils/paginator';
import { TipoDocumentoEnum } from '@shared/enums/auxiliar/tipo-documento.enum';

export abstract class DocumentacaoRepository {
    abstract getAllDocumentacaoStatusAtivo(): Observable<Either<Failure, DocumentacaoEntity[]>>;
    abstract getAllDocumentacaoStatusAtivoAndTipo(tipo: TipoDocumentoEnum): Observable<Either<Failure, DocumentacaoEntity[]>>;
    abstract getAllDocumentacaoPaginated(params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<DocumentacaoEntity[]>>>;
    abstract getDocumentacaoByIdPublic(idPublic: string): Observable<Either<Failure, DocumentacaoEntity>>;
    abstract updateStatusDocumentacaoByIdPublic(idPublic: string, status: boolean): Observable<Either<Failure, SuccessVoid>>;
    abstract updateDocumentacaoByIdPublic(param: DocumentacaoEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>>;
    abstract saveDocumentacao(param: DocumentacaoEntity): Observable<Either<Failure, SuccessVoid>>;
}