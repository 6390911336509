import { Injectable } from '@angular/core';

import { EnderecoModel } from './endereco.model';
import { EstadoMapper } from '../estado/estado.mapper';
import { MunicipioMapper } from '../municipio/municipio.mapper';
import { Mapper } from "@data/contracts/mapper.contract";
import { EnderecoEntity } from '@domain/entities/endereco/endereco.entity';
import { applyMaskNumeric } from '@presentation/shared/utils/utils';

@Injectable()
export class EnderecoMapper implements Mapper<EnderecoModel, EnderecoEntity> {

    constructor(
        private readonly _mapperEstado: EstadoMapper,
        private readonly _mapperMunicipio: MunicipioMapper,
    ) { }

    mapFrom(param: EnderecoEntity): EnderecoModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            municipio: this._mapperMunicipio.mapFrom(param?.municipio),
            cep: param?.cep?.toString()?.replace(/\D/g, ''),
            logradouro: param?.logradouro,
            numero: param?.numero,
            bairro: param?.bairro,
            complemento: param?.complemento,
        };
    }

    mapTo(param: EnderecoModel): EnderecoEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            cep: applyMaskNumeric(param?.cep?.toString()?.replace(/\D/g, ''), '00000-000'),
            logradouro: param?.logradouro,
            numero: param?.numero,
            bairro: param?.bairro,
            complemento: param?.complemento,
            estado: this._mapperEstado.mapTo(param?.municipio?.estado),
            municipio: this._mapperMunicipio.mapTo(param?.municipio),
        };
    }
}