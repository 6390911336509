import { Observable } from 'rxjs';

import { CursoEntity } from '@domain/entities/curso/curso.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { SuccessVoid } from '@infra/success/success';
import { PaginatorParam } from '@shared/utils/paginator';

export abstract class CursoRemoteDataSource {
    abstract getAllCursoStatusAtivo(): Observable<CursoEntity[]>;
    abstract getAllCursoPaginated(params: PaginatorParam): Observable<PaginatorEntity<CursoEntity[]>>;
    abstract getCursoByIdPublic(idPublic: string): Observable<CursoEntity>;
    abstract updateStatusCursoByIdPublic(idPublic: string, status: boolean): Observable<SuccessVoid>;
    abstract updateCursoByIdPublic(param: CursoEntity, idPublic: string): Observable<SuccessVoid>;
    abstract saveCurso(param: CursoEntity): Observable<SuccessVoid>;

    abstract getCursoTermoUsoEad(): Observable<Blob>;
    abstract getAllCursoStatusAtivoByIdPublicCategoria(idPublic: string): Observable<CursoEntity[]>;
}