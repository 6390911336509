import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@infra/auth/guards/auth.guard';
import { GetUserAuthGuard } from '@infra/auth/guards/get-user-auth.guard';

const routes: Routes = [
    { path: '', redirectTo: "autenticacao", pathMatch: "full" },
    { path: 'login', redirectTo: "/autenticacao/login", pathMatch: "full" },
    { path: 'mensagem', loadChildren: () => import('@presentation/shared/components/mensagens/mensagens.module').then(m => m.MensagensModule) },
    { path: 'autenticacao', loadChildren: () => import('@presentation/modules/autenticacao/autenticacao.module').then(m => m.AutenticacaoModule) },
    { path: 'cadastro', loadChildren: () => import('@presentation/modules/cadastro/cadastro.module').then(m => m.CadastroModule) },
    {
        path: '',
        canActivate: [AuthGuard, GetUserAuthGuard],
        children: [
            { path: 'area-usuario', loadChildren: () => import('@presentation/modules/area-usuario/area-usuario.module').then(m => m.AreaUsuarioModule)},
            // { path: 'orgao', loadChildren: () => import('@presentation/modules/orgao/orgao.module').then(m => m.OrgaoModule)},
            { path: 'academico', loadChildren: () => import('@presentation/modules/area-academica/area-academica.module').then(m => m.AreaAcademicaModule)},
            { path: 'configuracao', loadChildren: () => import('@presentation/modules/configuracao/configuracao.module').then(m => m.ConfiguracaoModule)},
            { path: 'professor', loadChildren: () => import('@presentation/modules/professor/professor.module').then(m => m.ProfessorModule)},
            { path: 'curso', loadChildren: () => import('@presentation/modules/curso/curso.module').then(m => m.CursoModule)},
            { path: 'usuario', loadChildren: () => import('@presentation/modules/usuario/usuario.module').then(m => m.UsuarioModule)},
        ]
    },
    { path: '**', redirectTo: "autenticacao", pathMatch: "full" }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
