import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { ArquivoUsuarioRemoteDataSource } from "@data/data-source/arquivo-usuario/arquivo-usuario-remote.datasource";
import { ArquivoUsuarioRepository } from "@domain/repositories/arquivo-usuario/arquivo-usuario.repository";
import { Failure } from '@infra/contracts/failure.contract';
import { SuccessVoid } from "@infra/success/success";

@Injectable()
export class ArquivoUsuarioRepositoryImpl implements ArquivoUsuarioRepository {

    constructor(
        private readonly _remoteDataSource: ArquivoUsuarioRemoteDataSource,
    ) { }

    getDocumentoByIdPublic(idPublic: string): Observable<Either<Failure, Blob>> {
        return this._remoteDataSource.getDocumentoByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map((res) => right(res)),
        );
    }
}