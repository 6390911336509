import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { SuccessVoid } from '@infra/success/success';
import { DocumentacaoRepository } from '@domain/repositories/documentacao/documentacao.repository';
import { DocumentacaoEntity } from '@domain/entities/documentacao/documentacao.entity';

@Injectable()
export class UpdateDocumentacaoByIdPublicUseCase implements Usecase<DocumentacaoEntity, SuccessVoid> {

    constructor(
        private readonly _repository: DocumentacaoRepository
    ) { }

    execute(param: DocumentacaoEntity, idPublic: string): Observable<SuccessVoid | Failure> {
        return this._repository.updateDocumentacaoByIdPublic(param, idPublic).pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<SuccessVoid>) => res.right)
        );
    }
}