import { Injectable } from '@angular/core';

import { OrgaoModel } from './orgao.model';
import { EnderecoMapper } from '../endereco/endereco.mapper';
import { Mapper } from "@data/contracts/mapper.contract";
import { OrgaoEntity } from '@domain/entities/orgao/orgao.entity';

@Injectable()
export class OrgaoMapper implements Mapper<OrgaoModel, OrgaoEntity> {

    constructor(
        private readonly _mapper: EnderecoMapper,
    ) { }

    mapFrom(param: OrgaoEntity): OrgaoModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            nome: param?.nome?.trim()?.toUpperCase(),
            sigla: param?.sigla?.trim()?.toUpperCase(),
            status: param?.status,
            articulador: param?.articulador?.trim(),
            email_articulador: param?.emailArticulador?.toLowerCase(),
            contato: param?.contato?.toString()?.replace(/\D/g, ''),
            endereco: this._mapper.mapFrom(param?.endereco),
        };
    }

    mapTo(param: OrgaoModel): OrgaoEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            nome: param?.nome?.trim()?.toUpperCase(),
            sigla: param?.sigla?.trim()?.toUpperCase(),
            status: param?.status,
            articulador: param?.articulador?.trim(),
            emailArticulador: param?.email_articulador?.toLowerCase(),
            contato: param?.contato?.toString()?.replace(/\D/g, ''),
            endereco: this._mapper.mapTo(param?.endereco)
        };
    }
}