import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

import { ExperienciaProfissionalModel } from './experiencia-profissional.model';
import { Mapper } from "@data/contracts/mapper.contract";
import { ExperienciaProfissionalEntity } from '@domain/entities/experiencia-profissional/experiencia-profissional.entity';

@Injectable()
export class ExperienciaProfissionalMapper implements Mapper<ExperienciaProfissionalModel, ExperienciaProfissionalEntity> {

    constructor(
        private readonly _datePipe: DatePipe,
    ) { }

    mapFrom(param: ExperienciaProfissionalEntity): ExperienciaProfissionalModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            instituicao: param?.instituicao,
            curso: param?.curso,
            carga_horaria: param?.cargaHoraria,
            inicio: param?.inicio,
            fim: param?.fim,
        };
    }

    mapTo(param: ExperienciaProfissionalModel): ExperienciaProfissionalEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            instituicao: param?.instituicao,
            curso: param?.curso,
            cargaHoraria: param?.carga_horaria,
            inicio: this._datePipe.transform(param?.inicio, 'yyyy-MM-dd'),
            fim: this._datePipe.transform(param?.fim, 'yyyy-MM-dd'),
        };
    }
}