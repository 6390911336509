import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { OrgaoRepository } from '@domain/repositories/orgao/orgao.repository';
import { OrgaoEntity } from '@domain/entities/orgao/orgao.entity';

@Injectable()
export class GetAllOrgaoStatusAtivoUseCase implements Usecase<void, OrgaoEntity[]> {

    constructor(
        private readonly _repository: OrgaoRepository
    ) { }

    execute(): Observable<OrgaoEntity[] | Failure> {
        return this._repository.getAllOrgaoStatusAtivo().pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<OrgaoEntity[]>) => res.right)
        );
    }
}