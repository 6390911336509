import { Injectable } from "@angular/core";
import { catchError, map } from 'rxjs/operators';
import { Either, left, right } from 'fp-ts/lib/Either';
import { Observable, throwError } from 'rxjs';

import { CursoRemoteDataSource } from "@data/data-source/curso/curso-remote.datasource";
import { CursoRepository } from "@domain/repositories/curso/curso.repository";
import { CursoEntity } from "@domain/entities/curso/curso.entity";
import { PaginatorEntity } from "@domain/entities/paginator/paginator.entity";
import { Failure } from '@infra/contracts/failure.contract';
import { SuccessVoid } from "@infra/success/success";
import { PaginatorParam } from "@shared/utils/paginator";

@Injectable()
export class CursoRepositoryImpl implements CursoRepository {

    constructor(
        private readonly _remoteDataSource: CursoRemoteDataSource,
    ) { }

    getAllCursoPaginated(params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<CursoEntity[]>>> {
        return this._remoteDataSource.getAllCursoPaginated(params).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getAllCursoStatusAtivo(): Observable<Either<Failure, CursoEntity[]>> {
        return this._remoteDataSource.getAllCursoStatusAtivo().pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    getCursoByIdPublic(idPublic: string): Observable<Either<Failure, CursoEntity>> {
        return this._remoteDataSource.getCursoByIdPublic(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }

    updateStatusCursoByIdPublic(idPublic: string, status: boolean): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateStatusCursoByIdPublic(idPublic, status).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    updateCursoByIdPublic(param: CursoEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.updateCursoByIdPublic(param, idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    saveCurso(param: CursoEntity): Observable<Either<Failure, SuccessVoid>> {
        return this._remoteDataSource.saveCurso(param).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(() => right(new SuccessVoid())),
        );
    }

    getCursoTermoUsoEad(): Observable<Either<Failure, Blob>> {
        return this._remoteDataSource.getCursoTermoUsoEad().pipe(
            catchError((error: Failure) => throwError(left(error))),
            map((res) => right(res)),
        );
    }

    getAllCursoStatusAtivoByIdPublicCategoria(idPublic: string): Observable<Either<Failure, CursoEntity[]>> {
        return this._remoteDataSource.getAllCursoStatusAtivoByIdPublicCategoria(idPublic).pipe(
            catchError((error: Failure) => throwError(left(error))),
            map(res => right(res)),
        );
    }
}