import { Observable } from 'rxjs';
import { Either } from 'fp-ts/Either';

import { Failure } from '@infra/contracts/failure.contract';
import { CursoMoodleEntity } from '@domain/entities/curso-moodle/curso-moodle.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorParam } from '@shared/utils/paginator';
import { SuccessVoid } from '@infra/success/success';

export abstract class CursoMoodleRepository {
    abstract getAllCursoMoodleStatusAtivo(): Observable<Either<Failure, CursoMoodleEntity[]>>;
    abstract syncCourseMoodle(): Observable<Either<Failure, SuccessVoid>>;
    abstract getAllCursoMoodlePaginated(params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<CursoMoodleEntity[]>>>;
    abstract getCursoMoodleByIdPublic(idPublic: string): Observable<Either<Failure, CursoMoodleEntity>>;
}