import { Injectable } from '@angular/core';

import { CursoMoodleModel } from './curso-moodle.model';
import { Mapper } from "@data/contracts/mapper.contract";
import { CursoMoodleEntity } from '@domain/entities/curso-moodle/curso-moodle.entity';

@Injectable()
export class CursoMoodleMapper implements Mapper<CursoMoodleModel, CursoMoodleEntity> {
    mapFrom(param: CursoMoodleEntity): CursoMoodleModel {
        return {
            id: param?.id,
            id_public: param?.idPublic,
            nome: param?.nome,
            status: param?.status,
        };
    }

    mapTo(param: CursoMoodleModel): CursoMoodleEntity {
        return {
            id: param?.id,
            idPublic: param?.id_public,
            nome: param?.nome,
            status: param?.status,
        };
    }
}