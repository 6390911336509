import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CursoMoodleRemoteDataSource } from './curso-moodle-remote.datasource';
import { Endpoints } from '@infra/constants/endpoints/endpoints';
import { CursoMoodleMapper } from '@data/models/curso-moodle/curso-moodle.mapper';
import { CursoMoodleEntity } from '@domain/entities/curso-moodle/curso-moodle.entity';
import { CursoMoodleModel } from '@data/models/curso-moodle/curso-moodle.model';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorMapper } from '@data/models/paginator/paginator.mapper';
import { PaginatorParam } from "@shared/utils/paginator";
import { HttpUtils } from '@shared/utils/http-utils';
import { SuccessVoid } from '@infra/success/success';

@Injectable()
export class CursoMoodleRemoteDataSourceImpl implements CursoMoodleRemoteDataSource {

    constructor(
        private readonly _http: HttpClient,
        private readonly _mapper: CursoMoodleMapper,
        private readonly _mapperPaginator: PaginatorMapper<CursoMoodleEntity[]>,
    ) { }

    getAllCursoMoodleStatusAtivo(): Observable<CursoMoodleEntity[]> {
        return this._http.get<CursoMoodleModel[]>(`${Endpoints.cursoMoodle}/all`).pipe(
            map(res => res['data']?.map(curso => this._mapper.mapTo(curso)))
        );
    }

    getAllCursoMoodlePaginated(params: PaginatorParam): Observable<PaginatorEntity<CursoMoodleEntity[]>> {
        return this._http.get<CursoMoodleModel[]>(`${Endpoints.cursoMoodle}`, { params: HttpUtils.buildParams(params.mapTo()) })
        .pipe(
            map((res) => this._mapperPaginator.mapPaging(
                res['data']?.map(curso => this._mapper.mapTo(curso)), 
                res['meta']
                )
            )
        );
    }

    getCursoMoodleByIdPublic(idPublic: string): Observable<CursoMoodleEntity> {
        return this._http.get<CursoMoodleEntity>(`${Endpoints.cursoMoodle}/${idPublic}`).pipe(
            map((res) => this._mapper.mapTo(res['data']))
        );
    }
    
    syncCourseMoodle(): Observable<SuccessVoid> {
        return this._http.get<SuccessVoid>(`${Endpoints.cursoMoodle}/sync`).pipe(
            map(res => new SuccessVoid())
        );
    }
}