import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Left, Right } from 'fp-ts/lib/Either';

import { Usecase } from '@data/contracts/usecase.contract';
import { Failure } from '@infra/contracts/failure.contract';
import { CursoRepository } from '@domain/repositories/curso/curso.repository';
import { CursoEntity } from '@domain/entities/curso/curso.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorParam } from '@shared/utils/paginator';

@Injectable()
export class GetAllCursoPaginatedUseCase implements Usecase<PaginatorParam, PaginatorEntity<CursoEntity[]>> {

    constructor(
        private readonly _repository: CursoRepository
    ) { }

    execute(params: PaginatorParam): Observable<PaginatorEntity<CursoEntity[]> | Failure> {
        return this._repository.getAllCursoPaginated(params).pipe(
            catchError((error: Left<Failure>) => throwError(error.left)),
            map((res: Right<PaginatorEntity<CursoEntity[]>>) => res.right)
        );
    }
}