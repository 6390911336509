import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { AuthRepository } from '@domain/repositories/auth/auth.repository';
import { AuthRepositoryImpl } from './repositories/auth/auth.repository-impl';
import { AuthUserRemoteDataSource } from './data-source/auth/auth-user-remote.datasource';
import { AuthUserRemoteDataSourceImpl } from './data-source/auth/auth-user-remote.datasource-impl';
import { AuthUserLocalDataSourceImpl } from './data-source/auth/auth-user-local.datasource-impl';
import { AuthUserLocalDataSource } from './data-source/auth/auth-user-local.datasource';

import { UserRepository } from '@domain/repositories/user/user.repository';
import { UserRepositoryImpl } from './repositories/user/user.repository-impl';
import { UserRemoteDataSource } from './data-source/user/user-remote.datasource';
import { UserRemoteDataSourceImpl } from './data-source/user/user-remote.datasource-impl';

import { AuxiliarRepository } from '@domain/repositories/auxiliar/auxiliar.repository';
import { AuxiliarRepositoryImpl } from './repositories/auxiliar/auxiliar.repository-impl';
import { AuxiliarRemoteDataSource } from './data-source/auxiliar/auxiliar-remote.datasource';
import { AuxiliarRemoteDataSourceImpl } from './data-source/auxiliar/auxiliar-remote.datasource-impl';

import { OrgaoRepository } from '@domain/repositories/orgao/orgao.repository';
import { OrgaoRepositoryImpl } from './repositories/orgao/orgao.repository-impl';
import { OrgaoRemoteDataSource } from './data-source/orgao/orgao-remote.datasource';
import { OrgaoRemoteDataSourceImpl } from './data-source/orgao/orgao-remote.datasource-impl';

import { EstadoRepository } from '@domain/repositories/estado/estado.repository';
import { EstadoRepositoryImpl } from './repositories/estado/estado.repository-impl';
import { EstadoRemoteDataSource } from './data-source/estado/estado-remote.datasource';
import { EstadoRemoteDataSourceImpl } from './data-source/estado/estado-remote.datasource-impl';

import { MunicipioRepository } from '@domain/repositories/municipio/municipio.repository';
import { MunicipioRepositoryImpl } from './repositories/municipio/municipio.repository-impl';
import { MunicipioRemoteDataSource } from './data-source/municipio/municipio-remote.datasource';
import { MunicipioRemoteDataSourceImpl } from './data-source/municipio/municipio-remote.datasource-impl';

import { CategoriaRepository } from '@domain/repositories/categoria/categoria.repository';
import { CategoriaRemoteDataSource } from './data-source/categoria/categoria-remote.datasource';
import { CategoriaRemoteDataSourceImpl } from './data-source/categoria/categoria-remote.datasource-impl';
import { CategoriaRepositoryImpl } from './repositories/categoria/categoria.repository-impl';

import { DocumentacaoRepository } from '@domain/repositories/documentacao/documentacao.repository';
import { DocumentacaoRemoteDataSource } from './data-source/documentacao/documentacao-remote.datasource';
import { DocumentacaoRemoteDataSourceImpl } from './data-source/documentacao/documentacao-remote.datasource-impl';
import { DocumentacaoRepositoryImpl } from './repositories/documentacao/documentacao.repository-impl';

import { ArquivoUsuarioRepository } from '@domain/repositories/arquivo-usuario/arquivo-usuario.repository';
import { ArquivoUsuarioRemoteDataSource } from './data-source/arquivo-usuario/arquivo-usuario-remote.datasource';
import { ArquivoUsuarioRemoteDataSourceImpl } from './data-source/arquivo-usuario/arquivo-usuario-remote.datasource-impl';
import { ArquivoUsuarioRepositoryImpl } from './repositories/arquivo-usuario/arquivo-usuario.repository-impl';

import { CursoRepository } from '@domain/repositories/curso/curso.repository';
import { CursoRemoteDataSource } from './data-source/curso/curso-remote.datasource';
import { CursoRemoteDataSourceImpl } from './data-source/curso/curso-remote.datasource-impl';
import { CursoRepositoryImpl } from './repositories/curso/curso.repository-impl';

import { CursoMoodleRepository } from '@domain/repositories/curso-moodle/curso-moodle.repository';
import { CursoMoodleRemoteDataSource } from './data-source/curso-moodle/curso-moodle-remote.datasource';
import { CursoMoodleRemoteDataSourceImpl } from './data-source/curso-moodle/curso-moodle-remote.datasource-impl';
import { CursoMoodleRepositoryImpl } from './repositories/curso-moodle/curso-moodle.repository-impl';

import { TurmaRepository } from '@domain/repositories/turma/turma.repository';
import { TurmaRemoteDataSource } from './data-source/turma/turma-remote.datasource';
import { TurmaRemoteDataSourceImpl } from './data-source/turma/turma-remote.datasource-impl';
import { TurmaRepositoryImpl } from './repositories/turma/turma.repository-impl';

import { ProfessorRepository } from '@domain/repositories/professor/professor.repository';
import { ProfessorRemoteDataSource } from './data-source/professor/professor-remote.datasource';
import { ProfessorRemoteDataSourceImpl } from './data-source/professor/professor-remote.datasource-impl';
import { ProfessorRepositoryImpl } from './repositories/professor/professor.repository-impl';

import { InscricaoRepository } from '@domain/repositories/inscricao/inscricao.repository';
import { InscricaoRemoteDataSource } from './data-source/inscricao/inscricao-remote.datasource';
import { InscricaoRemoteDataSourceImpl } from './data-source/inscricao/inscricao-remote.datasource-impl';
import { InscricaoRepositoryImpl } from './repositories/inscricao/inscricao.repository-impl';

import { PerfilRepository } from '@domain/repositories/perfil/perfil.repository';
import { PerfilRemoteDataSource } from './data-source/perfil/perfil-remote.datasource';
import { PerfilRemoteDataSourceImpl } from './data-source/perfil/perfil-remote.datasource-impl';
import { PerfilRepositoryImpl } from './repositories/perfil/perfil.repository-impl';

import { AuthMapper } from './models/auth/auth.mapper';
import { AuxiliarMapper } from './models/auxiliar/auxiliar.mapper';
import { OrgaoMapper } from './models/orgao/orgao.mapper';
import { UserMapper } from './models/user/user.mapper';
import { EstadoMapper } from './models/estado/estado.mapper';
import { MunicipioMapper } from './models/municipio/municipio.mapper';
import { EnderecoMapper } from './models/endereco/endereco.mapper';
import { PaginatorMapper } from './models/paginator/paginator.mapper';
import { PermissaoMapper } from './models/permissao/permissao.mapper';
import { CategoriaMapper } from './models/categoria/categoria.mapper';
import { PerfilMapper } from './models/perfil/perfil.mapper';
import { PessoaMapper } from './models/pessoa/pessoa.mapper';
import { DocumentacaoMapper } from './models/documentacao/documentacao.mapper';
import { CursoMapper } from './models/curso/curso.mapper';
import { CursoMoodleMapper } from './models/curso-moodle/curso-moodle.mapper';
import { TurmaMapper } from './models/turma/turma.mapper';
import { ProfessorMapper } from './models/professor/professor.mapper';
import { AreaInteresseMapper } from './models/area-interesse/area-interesse.mapper';
import { FormacaoProfissionalMapper } from './models/formacao-profissional/formacao-profissional.mapper';
import { ExperienciaProfissionalMapper } from './models/experiencia-profissional/experiencia-profissional.mapper';
import { InscricaoMapper } from './models/inscricao/inscricao.mapper';
import { ArquivoUsuarioMapper } from './models/arquivo-usuario/arquivo-usuario.mapper';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    DatePipe,

    AuthMapper,
    AuxiliarMapper,
    OrgaoMapper,
    UserMapper,
    EstadoMapper,
    MunicipioMapper,
    EnderecoMapper,
    PaginatorMapper,
    PermissaoMapper,
    CategoriaMapper,
    PerfilMapper,
    PessoaMapper,
    DocumentacaoMapper,
    CursoMapper,
    CursoMoodleMapper,
    TurmaMapper,
    ProfessorMapper,
    AreaInteresseMapper,
    FormacaoProfissionalMapper,
    ExperienciaProfissionalMapper,
    InscricaoMapper,
    ArquivoUsuarioMapper,

    { provide: AuthUserLocalDataSource, useClass: AuthUserLocalDataSourceImpl },
    
    { provide: AuthRepository, useClass: AuthRepositoryImpl },
    { provide: AuthUserRemoteDataSource, useClass: AuthUserRemoteDataSourceImpl },

    { provide: UserRepository, useClass: UserRepositoryImpl },
    { provide: UserRemoteDataSource, useClass: UserRemoteDataSourceImpl },

    { provide: AuxiliarRepository, useClass: AuxiliarRepositoryImpl },
    { provide: AuxiliarRemoteDataSource, useClass: AuxiliarRemoteDataSourceImpl },

    { provide: OrgaoRepository, useClass: OrgaoRepositoryImpl },
    { provide: OrgaoRemoteDataSource, useClass: OrgaoRemoteDataSourceImpl },

    { provide: EstadoRepository, useClass: EstadoRepositoryImpl },
    { provide: EstadoRemoteDataSource, useClass: EstadoRemoteDataSourceImpl },

    { provide: MunicipioRepository, useClass: MunicipioRepositoryImpl },
    { provide: MunicipioRemoteDataSource, useClass: MunicipioRemoteDataSourceImpl },

    { provide: CategoriaRepository, useClass: CategoriaRepositoryImpl },
    { provide: CategoriaRemoteDataSource, useClass: CategoriaRemoteDataSourceImpl },

    { provide: DocumentacaoRepository, useClass: DocumentacaoRepositoryImpl },
    { provide: DocumentacaoRemoteDataSource, useClass: DocumentacaoRemoteDataSourceImpl },

    { provide: ArquivoUsuarioRepository, useClass: ArquivoUsuarioRepositoryImpl },
    { provide: ArquivoUsuarioRemoteDataSource, useClass: ArquivoUsuarioRemoteDataSourceImpl },

    { provide: CursoRepository, useClass: CursoRepositoryImpl },
    { provide: CursoRemoteDataSource, useClass: CursoRemoteDataSourceImpl },

    { provide: CursoMoodleRepository, useClass: CursoMoodleRepositoryImpl },
    { provide: CursoMoodleRemoteDataSource, useClass: CursoMoodleRemoteDataSourceImpl },

    { provide: TurmaRepository, useClass: TurmaRepositoryImpl },
    { provide: TurmaRemoteDataSource, useClass: TurmaRemoteDataSourceImpl },

    { provide: ProfessorRepository, useClass: ProfessorRepositoryImpl },
    { provide: ProfessorRemoteDataSource, useClass: ProfessorRemoteDataSourceImpl },

    { provide: InscricaoRepository, useClass: InscricaoRepositoryImpl },
    { provide: InscricaoRemoteDataSource, useClass: InscricaoRemoteDataSourceImpl },

    { provide: PerfilRepository, useClass: PerfilRepositoryImpl },
    { provide: PerfilRemoteDataSource, useClass: PerfilRemoteDataSourceImpl },
  ]
})
export class DataModule { }
