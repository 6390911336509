import { Observable } from 'rxjs';
import { Either } from 'fp-ts/Either';

import { SuccessVoid } from '@infra/success/success';
import { Failure } from '@infra/contracts/failure.contract';
import { ProfessorEntity } from '@domain/entities/professor/professor.entity';
import { PaginatorEntity } from '@domain/entities/paginator/paginator.entity';
import { PaginatorParam } from '@shared/utils/paginator';

export abstract class ProfessorRepository {
    abstract getAllProfessorStatusAtivo(): Observable<Either<Failure, ProfessorEntity[]>>;
    abstract getMeuCredenciamentoProfessor(): Observable<Either<Failure, ProfessorEntity>>;
    abstract getAllProfessorPaginated(params: PaginatorParam): Observable<Either<Failure, PaginatorEntity<ProfessorEntity[]>>>;
    abstract getProfessorByIdPublic(idPublic: string): Observable<Either<Failure, ProfessorEntity>>;
    abstract updateStatusProfessorByIdPublic(idPublic: string, status: boolean): Observable<Either<Failure, SuccessVoid>>;
    abstract updateProfessorByIdPublic(param: ProfessorEntity, idPublic: string): Observable<Either<Failure, SuccessVoid>>;
    abstract updateProfessorCredenciamento(param: ProfessorEntity): Observable<Either<Failure, SuccessVoid>>;
    abstract saveProfessor(param: ProfessorEntity): Observable<Either<Failure, SuccessVoid>>;

    abstract deleteProfessorByIdPublic(idPublic: string): Observable<Either<Failure, SuccessVoid>>;
}